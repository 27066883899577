import request from "../tool/request";

const baseUrl = "/api/cate";

export const getCategorizeList = (params) => {
  return request({ url: `${baseUrl}/`, method: "get", params });
};

export const addCategorize = (data) => {
  return request({ url: `${baseUrl}/`, method: "post", data });
};

export const updateCategorize = (id, data) => {
  return request({ url: `${baseUrl}/${id}`, method: "patch", data });
};

export const deleteCategorize = (id) => {
  return request({ url: `${baseUrl}/${id}`, method: "delete" });
};

export const getCategorizeDetail = (id) => {
  return request({ url: `${baseUrl}/${id}`, method: "get" });
};

export const patchCategorizeShow = (id, data) => {
  return request({ url: `${baseUrl}/show/${id}`, method: "patch", data });
};