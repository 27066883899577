import React from 'react'

import { FloatButton } from 'antd'

import { Container } from './style'

export default (props) => {
  const handleClick = (item) => {
    if (item.link) {
      window.open(item.link)
    } else {
      alert(item.dialog)
    }
  }
  return (
    <Container>
      <FloatButton.Group
        shape="circle"
        style={{
          insetInlineEnd: 94,
        }}
      >
        <FloatButton.BackTop visibilityHeight={0} />
        {
          props.buttons.map((item, index) => {
            return (
              <div className='item' key={index} onClick={() => handleClick(item)}>
                <div className="img_box">
                  <img src={item.logo} />
                </div>
              </div>
            )
          })
        }
      </FloatButton.Group>
    </Container>

  )
}