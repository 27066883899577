import styled from "styled-components";

export const CommentBox = styled.div`

  height: ${props => props.height};
  overflow-y: hidden;
  min-height: 280px;

  .comment_item {
    padding: 10px 0;
    border-bottom: 1px solid #eee;

    .comment_item_name {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        color: #ee0a24;
        font-size: 18px;
        line-height: 1;
      }

      .time {
        font-size: 12px;
      }
    }

    .comment_item_describe, .comment_item_starts {
      font-size: 12px;
      line-height: 1;
      padding: 3px 0;
    }
  }

  .loop {
    animation: loop 35s linear infinite;
  }

  @keyframes loop {
    0% {
      transform: translateY(0);
    }
    99.99999999999% {
      transform: translateY(-49%);
    }
    100% {
      transform: translateY(0);
    }
  }
`

export const CenterAlign = styled.div`
  padding: 10px 0;
  text-align: center;
`