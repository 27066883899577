/**
 * 负责文章列表与文章添加/编辑组件的切换
 */

import React from 'react'
import {Route} from 'react-router-dom'
import ArticleList from './index.jsx'
import Create from './add_article/index.jsx'

const Article = () => {
  return (
    <div>
      <Route path='/home/article' exact component={ArticleList} />
      <Route path="/home/article/add_default/:id" component={Create} />
    </div>
  )
}

export default Article