import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal, Form, Input, message, InputNumber } from 'antd'
import { Header, FlexBox } from "./style"
import { getUserList, register, deleteUserApi, updateExpiresApi, resetPasswordApi } from "../../api/user";

const userType = {
  1: '超级管理员',
  2: '管理员'
}

export default () => {

  const columns = [
    { title: '用户名', dataIndex: 'name', key: 'name' },
    { title: '用户类型', dataIndex: 'type', render: (type) => userType[type] },
    { title: '可用天数', dataIndex: 'expires' },
    {
      title: '操作', render: (item) => {
        return (
          <FlexBox>
            <Button type="primary" onClick={() => updateExpires(item)}>修改可用天数</Button>
            <Button type="primary" onClick={() => udpatePassword(item)}>重置密码</Button>
            <Button type="primary" danger onClick={() => deleteUser(item)}>删除</Button>
          </FlexBox>
        )
      }
    }
  ]
  const [loading, updateLoading] = useState(true)
  const [createLoading, updateCreateLoading] = useState(false)
  const [userList, updateUserList] = useState([])
  const [openCreate, updateOpenCreate] = useState(false)
  const [onUser, updateOnUser] = useState()
  const [openExPires, updateOpenExpires] = useState(false)
  const [expiresLoading, updateExpiresLoading] = useState(false)

  const [resetModal, updateResetModal] = useState(false)
  const [resetLoading, updateResetLoading] = useState(false)


  const getUserListData = () => {
    getUserList().then(res => {
      updateUserList(res.data.map(item => {
        item.key = item.id
        return item
      }))
      updateLoading(false)
    })
  }

  useEffect(() => {
    getUserListData()
  }, [])

  const submitCreate = (values) => {
    updateCreateLoading(true)
    register(values).then(res => {
      updateOpenCreate(false)
      message.success("添加成功")
      updateLoading(true)
      getUserListData()
    }).finally(() => {
      updateCreateLoading(false)
    })
  }

  const updateExpires = (user) => {
    updateOnUser(user)
    updateOpenExpires(true)
  }

  const submitExpires = (values) => {
    if (!onUser.id) {
      message.error("请选择用户")
      return
    }
    updateExpiresLoading(true)
    updateExpiresApi(onUser.id, values).then(res => {
      message.success("修改成功")
      updateLoading(true)
      getUserListData()
      updateOpenExpires(false)
      updateExpiresLoading(false)
    }).catch(() => {
      updateExpiresLoading(false)
    })
  }

  const udpatePassword = (user) => {
    updateOnUser(user)
    updateResetModal(true)
  }

  const deleteUser = (user) => {
    Modal.confirm({
      title: "确定要删除吗？",
      content: <div>删除后将不可恢复，确定要删除用户 <strong>{user.name}</strong> 吗？</div>,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        updateLoading(true)
        deleteUserApi(user.id).then(res => {
          message.success("删除成功")
          getUserListData()
        }).catch(() => {
          updateLoading(false)
        })
      }
    })
  }

  const submitReset = (values) => {
    updateResetLoading(true)
    resetPasswordApi(onUser.id, values).then(res => {
      message.success("重置成功")
      updateResetModal(false)
      updateLoading(true)
      getUserListData()
    }).catch(() => {
      updateResetLoading(false)
    })
  }

  return (
    <>
      <Card title="用户管理">
        <Header>
          <div></div>
          <Button type="primary" onClick={() => { updateOpenCreate(true) }}>添加用户</Button>
        </Header>
      </Card>
      <br />
      <Card loading={loading}>
        <Table columns={columns} dataSource={userList}></Table>
      </Card>

      <Modal title="修改可用天数" open={openExPires} footer={null} destroyOnClose onCancel={() => updateOpenExpires(false)}>
        <Form initialValues={{ expires: onUser?.expires }} labelWrap={{ span: 5 }} onFinish={submitExpires} style={{ padding: '20px' }}>
          <Form.Item label="可用天数" name="expires" rules={[{ required: true, message: '请输入可用天数' }]}>
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit" loading={expiresLoading}>提交</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="重置密码" open={resetModal} footer={null} destroyOnClose onCancel={() => updateResetModal(false)}>
        <Form labelWrap={{ span: 5 }} onFinish={submitReset} style={{ padding: '20px' }}>
        <Form.Item label="新密码" name="password" rules={[{ required: true, message: '请输入密码' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit" loading={resetLoading}>提交</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="添加用户" open={openCreate} footer={null} onCancel={() => updateOpenCreate(false)} destroyOnClose>
        <Form onFinish={submitCreate} labelCol={{ span: 5 }} style={{ padding: '20px' }} loading={createLoading}>
          <Form.Item label="用户名" name="name" rules={[{ required: true, message: '请输入用户名' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="密码" name="password" rules={[{ required: true, message: '请输入密码' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item label="可用天数" name="expires" rules={[{ required: true, message: '请输入可用天数' }]}>
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit" loading={createLoading}>提交</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}