import {useEffect, useState} from 'react'
import {Card, Table, Modal, Input, Button, Switch, Form, message} from 'antd'
import moment from "moment";

import {getPayConfig, putPayConfig, patchPayActive} from "../../api/payConfig"

const defaultData = [
  { pay_name: 'stripe', APP_ID: '', KEY: '', SECRET_KEY: '', PM_ID: '', active: 0 },
  { pay_name: 'paypal', APP_ID: '', KEY: '', SECRET_KEY: '', PM_ID: '', active: 0 },
  { pay_name: 'possions', APP_ID: '', KEY: '', SECRET_KEY: '', PM_ID: '', active: 0 }
]

export default () => {
  const [data, updateData] = useState([])
  const [loading, updateLoading] = useState(true)
  const [visible, updateVisible] = useState(false)
  const [formdata, updateFormdata] = useState({})
  const [formLoading, updateFormLoading] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    getPayConfig().then(res => {
      updateData(defaultData.map(item => {
        const resItem = res.data.find(i => i.pay_name === item.pay_name)
        return resItem || item
      }))
    }).finally(() => {
      updateLoading(false)
    })
  }

  const columns = [
    { title: '支付平台', dataIndex: 'pay_name', key: 'pay_name' },
    {
      title: '是否激活',
      render: (config) => <Switch loading={formLoading} checkedChildren="开启" unCheckedChildren="关闭" checked={config.active} onChange={e => changeActive(config.id, e)} />
    },
    {
      title: '更新时间',
      render: (config) => <span>{config.updateTime && moment(config.updateTime * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
    },
    {
      title: '操作',
      render: (config) => <Button type="primary" onClick={() => openEdit(config)}>编辑</Button>
    }
  ]

  const changeActive = (id, active) => {
    console.log(id, active)
    if(!id){
      message.error("请先配置支付参数后再激活")
      return
    }
    updateFormLoading(true)
    patchPayActive(id, { active: active ? 1 : 0 }).then(() => {
      message.success("修改成功")
      updateLoading(true)
      getData()
    }).finally(() => {
      updateFormLoading(false)
    })
  }

  const openEdit = (config) => {
    updateFormdata(config)
    updateVisible(true)
    form.setFieldsValue(config)
  }

  const submitConfig = (values) => {
    updateFormLoading(true)
    putPayConfig({ ...values, id: formdata.id }).then(res => {
      updateVisible(false)
      updateLoading(true)
      message.success("修改成功")
      getData()
    }).finally(() => {
      updateFormLoading(false)
    })
  }

  return (
    <Card title="支付配置" loading={loading}>
      <Table dataSource={data} columns={columns} />
      <Modal open={visible} title="编辑支付配置" footer={null} onCancel={() => updateVisible(false)} destroyOnClose>
        <Form style={{ paddingTop: '20px' }} labelCol={{ span: 5 }} form={form} onFinish={submitConfig}>
          <Form.Item name="pay_name" label="平台名称" rules={[{ required: true, message: "请输入平台名称" }]}>
            <Input disabled />
          </Form.Item>

          <Form.Item name="KEY" label="KEY" rules={[{ required: true, message: "请输入KEY" }]}>
            <Input.TextArea placeholder="请输入KEY" rows={4} />
          </Form.Item>

          {/*<Form.Item name="APP_ID" label="APP_ID" rules={[{ required: true, message: "请输入APP_ID" }]}>*/}
          {/*  <Input.TextArea placeholder="请输入APP_ID" />*/}
          {/*</Form.Item>*/}

          {
            formdata.pay_name !== 'stripe' && (
              <Form.Item name="SECRET_KEY" label="SECRET_KEY" rules={[{ required: true, message: "请输入SECRET_KEY" }]}>
                <Input.TextArea placeholder="请输入SECRET_KEY" />
              </Form.Item>
            )
          }

          {
            formdata.pay_name === 'possions' && (
              <Form.Item name="PM_ID" label="PM_ID" rules={[{ required: true, message: "请输入PM_ID" }]}>
                <Input.TextArea placeholder="请输入PM_ID" />
              </Form.Item>
            )
          }
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button loading={formLoading} type="primary" htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}