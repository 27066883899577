import React, {useState} from "react";

import {Upload, message, Modal, Image} from 'antd';
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import {FileList} from "./style"

const UploadBtn = (props) => {
  return (
    <div>
      {props.logoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>点击上传</div>
    </div>
  )
}


const ImgBox = (props) => {
  return (
    <div className='img_box'>
      <Image src={props.src} alt="avatar" />
      <div className="delete" onClick={() => props.delete(props.src)}><span>X</span></div>
    </div>
  )
}

export default (props) => {


  const [logoLoading, setLogoLoading] = useState(false)
  let uploadingFiles = []
  let fileUrls = []

  // 上传封面前检测文件类型
  const handleUploadBefore = (file) => {
    const { type } = file
    console.log('before', file)
    return type === 'image/png' || type === 'image/jpeg' || type === 'image/webp'
  }

  // 上传封面
  const handleUploadChange = (info) => {
    const { file, fileList } = info;
    uploadingFiles = fileList

    if (file.status === 'done') {
      const { url } = file.response
      if (url) {
        fileUrls = [...fileUrls,url]
      }
      checkAllFilesUploaded();
    } else if (file.status === 'error') {
      message.error(`${file.name} 文件上传失败.`);
      setLogoLoading(false)
    } else {
      setLogoLoading(true)
    }
  }

  const checkAllFilesUploaded = () => {
    const allFilesUploaded = uploadingFiles.every(file => file.status === 'done')
    if (allFilesUploaded && uploadingFiles.length > 0) {
      if (props.list) {
        props.onChange && props.onChange([...props.value, ...fileUrls])
      } else {
        props.onChange && props.onChange(fileUrls[0])
      }
      setLogoLoading(false)
      message.success('上传成功')
    }
  };

  const handleDeleteBanner = (item) => {
    Modal.confirm({
      title: '确定要删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        props.onChange(props.list ? props.value.filter(banner => banner !== item) : '');
      }
    })
  }


  return (
    <FileList>
      {
        props.list && props.value?.map(item => <ImgBox src={item} key={item} delete={handleDeleteBanner} />)
      }
      {
        props.list ?
          <Upload
            name="file"
            disabled={logoLoading}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="/api/image/uploadfile"
            beforeUpload={handleUploadBefore}
            onChange={handleUploadChange}
            accept=".jpg,.png,.webp"
            multiple
          >
            {
              props.list ? <UploadBtn logoLoading={logoLoading} /> : <div className='img_box'>{logoLoading ? <LoadingOutlined /> : <UploadBtn logoLoading={logoLoading} />}</div>
            }
          </Upload>
          :
          props.value ? <ImgBox src={props.value} delete={handleDeleteBanner} /> :
            <Upload
              name="file"
              disabled={logoLoading}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="/api/image/uploadfile"
              beforeUpload={handleUploadBefore}
              onChange={handleUploadChange}
              accept=".jpg,.png,.webp"
            >
              {
                props.list ? <UploadBtn logoLoading={logoLoading} /> : <div className='img_box'>{logoLoading ? <LoadingOutlined /> : <UploadBtn logoLoading={logoLoading} />}</div>
              }
            </Upload>
      }

    </FileList>
  )
}