import React, {useState, useEffect} from "react";
import {Form, Input, Select, Button, Card, message, InputNumber} from "antd"

import {uploadImage} from "../../../api/image"
import {addArticle, getArticleDetail, updateArticle} from "../../../api/article"
import {getTemplateList} from "../../../api/template"
import BraftEditor from "braft-editor";

export default (props) => {
  const [editorState, setEditorState] = useState("");
  const [loading, updateLoading] = useState(true)
  const [template, updateTemplate] = useState([])

  const [form] = Form.useForm();

  useEffect(() => {

    getTemplateList().then(res => {
      updateTemplate(res.data)
    })

    const { id } = props.match.params
    if (id === '0') {
      updateLoading(false)
    } else {
      getArticleDetail(id).then(res => {
        const { context, title, sort, type, template } = res.data
        setEditorState(context)

        form.setFieldValue('title', title)
        form.setFieldValue('sort', sort)
        form.setFieldValue('type', String(type))
        form.setFieldValue('template', template)
        form.setFieldValue('context', BraftEditor.createEditorState(context))
        updateLoading(false)
      })
    }
  }, [])
  const handleEditorChange = editorState => {
    setEditorState(editorState.toHTML());
  };

  // 富文本上传媒体文件
  const handleEditorUploadMedia = param => {
    const fd = new FormData();
    fd.append("file", param.file);
    uploadImage(fd).then(res => {
      const { url } = res.data
      param.success({
        url: url,
        meta: {
          loop: true, // 指定音视频是否循环播放
          autoPlay: true, // 指定音视频是否自动播放
          controls: false, // 指定音视频是否显示控制栏
          playsinline: true
        },
      });
    })
  };
  const submitArticle = (values) => {
    if (editorState.length < 1) {
      message.error("请输入文章内容")
      return
    }
    const { id } = props.match.params
    updateLoading(true)
    if (id === '0') {
      addArticle({ ...values, context: editorState }).then(res => {
        message.success("添加成功")
        props.history.push({ pathname: '/home/article' })
      }).finally(() => {
        updateLoading(false)
      })
    } else {
      updateArticle(id, { ...values, context: editorState }).then(res => {
        message.success("修改成功")
        props.history.push({ pathname: '/home/article' })
      }).finally(() => {
        updateLoading(false)
      })
    }


  }
  return (
    <Card title="添加文章" loading={loading}>
      <Form labelCol={{ span: 2 }} onFinish={submitArticle} form={form}>
        <Form.Item label="文章名称" name="title" rules={[{ required: true, message: "请输入文章名称" }]}>
          <Input placeholder="请输入文字名称" />
        </Form.Item>
        <Form.Item label="排序" name="sort" rules={[{ required: true, message: "请输入文章排序" }]}>
          <InputNumber placeholder="请输入文章排序" min={1} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="文章分类" name="type" rules={[{ required: true, message: "请选择文章分类" }]}>
          <Select placeholder="请选择文章分类">
            <Select.Option value="1">首页</Select.Option>
            <Select.Option value="2">商品详情页</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="文章模板" name="template" rules={[{ required: true, message: "请选择文章模板" }]}>
          <Select placeholder="请选择文章模板">
            {template.map(item => <Select.Option value={item.key}>{item.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item label="文章内容" name="context">
          <BraftEditor
            style={{ border: '1px solid #ccc' }}
            value={editorState}
            onChange={handleEditorChange}
            media={{ uploadFn: handleEditorUploadMedia }}
            placeholder="请输入文章详情"
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 2 }}>
          <Button type="primary" htmlType="submit">保存</Button>
        </Form.Item>
      </Form>
    </Card>
  );
}