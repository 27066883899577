import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import {getArticleList} from "../../../../api/article";

import {Fbox} from "./style"

export default () => {
  const [articles, updateArticles] = useState([])
  const [config, updateConfig] = useState({})

  useEffect(() => {
    getArticleList({ type: 1 }).then(res => updateArticles(res.data.list))
    let config = window.localStorage.getItem('config')
    config = JSON.parse(config)
    const template = config.find(item => item.type === 'template')
    updateConfig(template)
  }, [])


  return (
    <Fbox>
      <div className="context">
        <div className="text">

          <div className="menu">
            <span className="title">{config.index_bottom_left_title || '购物说明'}</span>
            {articles.map(item => <Link to={`/article/${item.id}`} key={item.id}><div className="item">{item.title}</div></Link>)}
          </div>

          <div className="connect">
            <span className="title">{config?.index_bottom_right_title || '联络我们'}</span>
            <div className="table">
              <div className="row">
                <span className="label">{config?.index_bottom_right_row1}</span>
                {/*<div className="val">*/}
                {/*  <span>(UTC/GMT+8:00)</span>*/}
                {/*  <span>{kefuTime}</span>*/}
                {/*</div>*/}

              </div>

              <div className="row">
                <span className="label">{config?.index_bottom_right_row2}</span>
                {/*<span className="val">{kefuCode}</span>*/}
              </div>

            </div>
          </div>
        </div>

        <div className="pay_list">
          <img src={config?.index_bottom_img} alt="" />
        </div>

      </div>
    </Fbox>
  )
}