import React, {useEffect, useState} from "react";
import {Card, Table, Image, Button, message, Form, Modal, Input, Col, Row} from 'antd'

import UploadList from "../../component/upload"
import {patchTemplate} from "../../api/user"
import {getTemplateList, updateTemplate} from "../../api/template";

export default () => {

  const [templateList, setTemplateList] = useState([])
  const [loading, setLoading] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [selectedTemplate, updateSelectedTemplate] = useState(null)
  const [openEdit, updateOpenEdit] = useState(false)

  const [form] = Form.useForm()

  const columns = [
    { title: '模板名称', dataIndex: 'name', key: 'name' },
    { title: '模板预览', dataIndex: 'preview', key: 'preview', render: (preview) => <Image src={preview} width={100} /> },
    {
      title: '操作', render: (template) => {
        return (
          <div>
            <Button type="primary" onClick={() => selectTemplate(template.key)} loading={uploading} disabled={template.is_select}>{template.is_select ? '已选' : '选择'}</Button>
            <Button loading={uploading} type="primary" onClick={() => openEditModal(template)} style={{ marginLeft: '10px' }}>编辑</Button>
          </div>
        )
      }
    },
  ]


  const selectTemplate = (id) => {
    setUploading(true)
    patchTemplate(id).then(() => {
      message.success("操作成功")
      setLoading(true)
      getTemplateList().then(res => {
        setTemplateList(res.data)
      }).finally(() => setLoading(false))
    }).finally(() => {
      setUploading(false)
    })
  }

  useEffect(() => {
    getTemplateList().then(res => {
      setTemplateList(res.data)
      setLoading(false)
    })
  }, [])

  const openEditModal = (template) => {
    updateSelectedTemplate(template)
    updateOpenEdit(true)
    console.log(template)
    form.setFieldsValue(template)
  }

  const submitEdit = (values) => {
    console.log(values)
    setUploading(true)
    updateTemplate(selectedTemplate.id, values).then(res => {
      message.success("修改成功")
      updateOpenEdit(false)
      getTemplateList().then(res => {
        setTemplateList(res.data)
        setLoading(false)
      })
    }).finally(() => {
      setUploading(false)
    })
  }

  return (
    <>
      <Card title="模板管理">
        <Table columns={columns} dataSource={templateList} loading={loading} />
      </Card>
      <Modal title="编辑模板" open={openEdit} width={800} style={{ height: '500px' }} footer={null} onCancel={() => updateOpenEdit(false)}>
        <Form style={{ paddingTop: '20px' }} labelCol={{ span: 5 }} onFinish={submitEdit} form={form}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="预览图" name="preview" rules={[{ required: true, message: '请上传预览图' }]} labelCol={{ span: 7, offset: 3 }}>
                <UploadList />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="首页底部图片" name="index_bottom_img" rules={[{ required: true, message: '请上传首页底部图片' }]} labelCol={{ span: 7, offset: 6 }}>
                <UploadList />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="模板名称" name="name" rules={[{ required: true, message: '请输入模板名称' }]}>
            <Input placeholder="请输入模板名称" />
          </Form.Item>

          <Form.Item label="底部版权文字" name="copyright_text" rules={[{ required: true, message: '请输入底部版权文字' }]}>
            <Input placeholder="请输入底部版权文字" />
          </Form.Item>

          <Form.Item label="首页底部左侧标题" name="index_bottom_left_title">
            <Input placeholder="请输入首页底部左侧标题" />
          </Form.Item>
          <Form.Item label="首页底部右侧标题" name="index_bottom_right_title">
            <Input placeholder="请输入首页底部右侧标题" />
          </Form.Item>

          <Form.Item label="首页底部右侧第一行" name="index_bottom_right_row1">
            <Input placeholder="请输入首页底部右侧第一行" />
          </Form.Item>

          <Form.Item label="首页底部右侧第二行" name="index_bottom_right_row2">
            <Input placeholder="请输入首页底部右侧第二行" />
          </Form.Item>

          <Form.Item label="商品标签" name="goods_tags">
            <Input placeholder="请输入商品标签,多个请用逗号隔开" />
          </Form.Item>
          {/*<Form.Item label="商品销售数量" name="goods_out_num">*/}
          {/*  <Input placeholder="请输入商品销售数量" />*/}
          {/*</Form.Item>*/}
          {/*<Form.Item label="商品总数" name="goods_total_num">*/}
          {/*  <Input placeholder="请输入商品总数" />*/}
          {/*</Form.Item>*/}

          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button loading={uploading} type="primary" htmlType="submit">提交</Button>
          </Form.Item>

        </Form>
      </Modal>
    </>
  )
}