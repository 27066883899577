import Article from './pages/article/router.jsx'
import Order from './pages/order'
import Column from "./pages/column/index.jsx"
import Config from "./pages/config/index.jsx"
import User from "./pages/user_conetent/index"
import Goods from "./pages/goods/router"
import Template from "./pages/template/index.jsx"
import PayConfig from "./pages/pay_comfig/index"

import { BookOutlined, FileImageOutlined, SnippetsOutlined, SettingOutlined } from '@ant-design/icons';

const routers = [
  {
    path: '/home',
    name: '系统配置',
    component: Config,
    icon: <SettingOutlined />
  },
  {
    path: '/users',
    name: '用户管理',
    component: User,
    meta: {
      auth: true
    },
    icon: <BookOutlined />
  },
  {
    path: '/article',
    name: '文章管理',
    component: Article,
    icon: <BookOutlined />
  },
  {
    path: '/goods',
    name: '商品管理',
    component: Goods,
    icon: <BookOutlined />
  },
  {
    path: '/orders',
    name: "订单管理",
    component: Order,
    icon: <FileImageOutlined />
  },
  {
    path: '/column',
    name: '分类管理',
    component: Column,
    icon: <SnippetsOutlined />
  },
  {
    path: '/template',
    name: "模板管理",
    component: Template,
    icon: <BookOutlined />
  },
  {
    path: '/pay_config',
    name: "支付配置",
    component: PayConfig,
    icon: <BookOutlined />
  }
]

export default routers