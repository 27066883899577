import React, { useState, useEffect } from "react";
import { Pagination, Spin } from 'antd'

import { CBox } from "./style"
import GoodBox from "../goodBox/"
import { getGoodsList } from "../../../../api/goods"


export default (props) => {

  const [page, updatePage] = useState(1)
  const page_size = 4
  const [loading, updateLoaidng] = useState(true)
  const [total, updateTotal] = useState(0)


  const [data, updateData] = useState([])

  useEffect(() => {
    updateLoaidng(true)
    getGoodsList({ cate: props.column.id, page, page_size }).then(res => {
      updateData(res.data.data.list)
      updateTotal(res.data.data.total)
    }).finally(() => {
      updateLoaidng(false)
    })
  }, [page])



  return (
    <Spin spinning={loading}>
      {
        data.length >= 1 ? (
          <CBox>
            <div className="title">
              <i className="prefix">------</i>
              <span>{props.column.name}</span>
              <i className="suffix">------</i>
            </div>
            <div className="content">
              {
                data.map((item) => <GoodBox key={item.id} {...item} />)
              }
            </div>
            <div className="page">
              <Pagination defaultCurrent={page} current={page} pageSize={page_size} total={total} onChange={(page) => updatePage(page)} />
            </div>
          </CBox>
        ) : ""
      }
    </Spin>
  )
}