import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Input, Image, message, Spin} from "antd";
import axios from "axios";

import {ImgBox, Main} from "./style";

const {Search} = Input

export default function HashSearch() {
  const {hash} = useParams()
  const [hashVal, setHashVal] = useState(hash)

  const [loading, setloading] = useState(true)
  const [imgList, setImgList] = useState([])

  useEffect(() => {
    getHashImg()
  }, [])

  const getHashImg = () => {
    if (!hashVal) {
      setloading(false)
      return
    }
    setloading(true)
    axios.get('/jie/api/new/getHashImgOne', {
      params: {hashVal}
    }).then(res => {
      console.log(res.data)
      const {ecode, msg, data} = res.data
      if (ecode === -1) {
        message.warning(msg)
      } else {
        setImgList(data.url.split(','))
      }
      setloading(false)
    }).catch(err => {
      message.error("获取hash失败，请稍后再试或联系管理员")
      console.log(err)
      setloading(false)
    })
  }

  const changeInput = (e) => {
    setHashVal(e.target.value)
  }

  return (
    <Main>
      <div className="searchBox">
        <Search placeholder="请输入hash值" enterButton onSearch={getHashImg} onChange={changeInput} value={hashVal}/>
      </div>
      <ImgBox>
        {loading ? (<div className="example"><Spin/></div>) : <></>}

        {
          imgList.length >= 1 ? (
            <Image.PreviewGroup>
              {
                imgList.map(item => <Image height={170} src={item}/>)
              }
            </Image.PreviewGroup>
          ) : <div className="not_found">没有可用照片，请输入hash值开始查询</div>
        }

      </ImgBox>
    </Main>
  )
}