import styled from "styled-components";

export const Container = styled.div`
  .inline_box{
    display: inline-block;
    margin-left: 15px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`