import React, { useEffect, useState } from "react"
import { Link, useParams, useLocation } from "react-router-dom"
import { Select, Spin } from 'antd'

import Header from "../component/header/"
import GoodBox from "../component/goodBox"
import Footer from "../component/footer"

import { Process, Container } from "./style"

import { getCategorizeList, getCategorizeDetail } from "../../../api/categorize"
import { getGoodsList } from "../../../api/goods"

const sortList = [
  { name: "上架時間: 由新到舊", value: 'ctime:desc' },
  { name: "上架時間: 由舊到新", value: 'ctime:asc' },
  { name: "價格: 由高至低", value: 'price:desc' },
  { name: "價格: 由低至高", value: 'price:asc' },
]

const pageSize = [
  { name: "每頁顯示 24 個", value: 24 },
  { name: "每頁顯示 48 個", value: 48 },
  { name: "每頁顯示 72 個", value: 72 }
]

export default () => {
  const { id } = useParams()
  const location = useLocation()

  const [loading, updateLoading] = useState(true)
  const [menu, updateMenu] = useState([])
  const [currentCate, updateCurrentCate] = useState({})

  const [sort, updateSort] = useState('ctime:desc')
  const [page_size, updatePage] = useState(24)
  const [total, updateTotal] = useState(0)
  const [title, updateTitle] = useState('')

  const [goods, updateGoods] = useState([])

  useEffect(() => {
    if (!id) return
    if (id !== 'search') {
      getCategorizeDetail(id).then(res => updateCurrentCate(res.data))
    }
    getCategorizeList({ page: 1, page_size: 99999, is_show: 1 }).then(res => updateMenu(res.data.list))
  }, [id])

  useEffect(() => {
    if (!id) return
    const formdata = { sort, page_size }
    if (id === 'search') {
      const urlParams = new URLSearchParams(location.search)
      formdata.name = urlParams.get('name')
    } else {
      formdata.cate = id
    }
    updateLoading(true)
    getGoodsList(formdata).then(res => {
      const { list, total } = res.data.data
      updateGoods(list)
      updateTotal(total)
    }).finally(() => {
      updateLoading(false)
    })
  }, [sort, page_size, id, location])

  useEffect(() => {
    updateTitle(currentCate.name)
  }, [currentCate])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    updateTitle(`搜索：${urlParams.get('name')}`)
  }, [location])



  return (
    <Spin spinning={loading}>
      <Header fixedClass="fixed" />
      <Process>
        <div className="context">
          <div>全部商品/{title}</div>
        </div>
      </Process>
      <Container>
        <div className="menu">
          <div className="item">
            <Link to="/">首页</Link>
          </div>
          {
            menu.map(item => (
              <div className={`item ${(currentCate.name === item.name) && 'active'}`} key={item.id}>
                <Link to={`/products/${item.id}`}>{item.name}</Link>
              </div>
            ))
          }
        </div>

        <div className="content">
          <div className="header">
            <div className="current">{title}</div>
            <div className="select_box">
              <Select defaultValue={sort} onChange={updateSort}>
                {sortList.map(item => <Select.Option value={item.value} key={item.value}>{item.name}</Select.Option>)}
              </Select>
              <Select defaultValue={page_size} onChange={updatePage}>
                {pageSize.map(item => <Select.Option value={item.value} key={item.value}>{item.name}</Select.Option>)}
              </Select>
            </div>
          </div>

          <div className="good_list">
            {goods.map(item => <GoodBox key={item.id} {...item} />)}
          </div>
        </div>

      </Container>

      <Footer />
    </Spin>
  )
}