import React, {useEffect, useState} from "react"
import {Button, Carousel, Select, Collapse, Form, Input, Radio, Spin} from 'antd';
import Comment from "../../../../component/comment";
import GoodBox from "../../component/goodBox";

import {getGoodsList} from "../../../../api/goods"
import {createOrder} from "../../../../api/order"
import {getStateList, getCityList, getRegionList} from "../../../../api/location"
import {getArticleList} from "../../../../api/article"

import {Container} from '../style'
import AffixButton from "../../component/affix_button";
import LoopText from "../../component/loop_text"

import currencyList from '../../../goods/currency'

export default (props) => {
  const tags = ["免運費", "貨到付款", "七天鑒賞期"]

  const goodDetail = props.detail
  const [goods, updateGoods] = useState({})
  const [num, updateNum] = useState(1)

  const [pay_type, updatePayType] = useState()
  const [send_type, updateSendType] = useState()

  const [loading, updateLoading] = useState(false)
  const [goodsList, updateGoodsList] = useState([])

  const [stateList, updateStateList] = useState([])
  const [cityList, updateCityList] = useState([])
  const [regionList, updateRegion] = useState([])

  const [selectedCountry, updateSelectedCountry] = useState(null)
  const [selectedState, updateSelectedState] = useState(null)
  const [selectedCity, updateSelectedCity] = useState(null)
  const [selectedRegion, updateSelectedRegion] = useState(null)


  const [currencyIcon, updateCurrencyIcon] = useState("")
  const [footer, uppdateFooter] = useState([])

  const [form] = Form.useForm()

  useEffect(() => {
    if (selectedCountry) {
      getStateList(selectedCountry).then(res => updateStateList(res.data.map(item => {
        item.key = item.id
        item.value = item.id
        item.label = item.name
        return item
      })))
      updateSelectedState(null)
      updateSelectedCity(null)
      updateSelectedRegion(null)
    }
  }, [selectedCountry])

  useEffect(() => {
    if (selectedState) {
      getCityList(selectedState).then(res => updateCityList(res.data.map(item => {
        item.key = item.id
        item.value = item.id
        item.label = item.name
        return item
      })))
      updateSelectedCity(null)
      updateSelectedRegion(null)
    }
  }, [selectedState])

  useEffect(() => {
    if (selectedCity) {
      getRegionList(selectedCity).then(res => updateRegion(res.data.map(item => {
        item.key = item.id
        item.value = item.id
        item.label = item.name
        return item
      })))
      updateSelectedRegion(null)
    }
  }, [selectedCity])

  const handleUpdateNum = (ctype) => {
    let newNum = num
    newNum = ctype === 'add' ? newNum + 1 : newNum - 1
    if (newNum < 1) newNum = 1
    updateNum(newNum)
  }


  useEffect(() => {
    getGoodsList({ page: 1, page_size: 6 }).then(res => {
      updateGoodsList(res.data.data.list)
    })
    getArticleList({ type: 2 }).then(res => {
      uppdateFooter(res.data.list.map(item => {
        return {
          key: item.id,
          label: item.title,
          children: <div dangerouslySetInnerHTML={{ __html: item.context }}></div>,
        }
      }))
    })

  }, [])

  useEffect(() => {
    if (props.send_list) {
      updateSendType(props.send_list[0])
      form.setFieldValue('send_type', props.send_list[0])
    }
  }, [props.send_list])

  useEffect(() => {
    if (props.pay_list) {
      updatePayType(props.pay_list[0])
      form.setFieldValue('pay_type', props.pay_list[0])
    }
  }, [props.pay_list])

  useEffect(() => {
    if (!goods.name && goodDetail.specifications) {
      updateGoods(goodDetail.specifications[0])
    }

    if (props.detail.context) {
      const videos = document.querySelectorAll("video")
      for (let item of videos) {
        item.setAttribute("playsInline", true)
      }
    }
    updateCurrencyIcon(currencyList.find(item => item.value === goodDetail.currency)?.icon || "￥")
  }, [props.detail]);

  const handleCreateOrder = (values) => {
    updateLoading(true)
    values.areas = [selectedCountry, selectedState, selectedCity, selectedRegion].join(',')
    createOrder({ ...values, num, goods_id: goodDetail.id, specifications: goods?.id }).then(res => {
      window.location.href = res.data.data.pay_url
    }).catch(() => {
      updateLoading(false)
    })
  }


  return (
    <Container>
      <div className="loop_box">
        <LoopText />
      </div>
      <AffixButton buttons={props.affixMenus} />
      <div className="header">
        <span>{goodDetail.name}</span>
      </div>
      <div className="banners">
        <Carousel autoplay>
          {
            goodDetail.banners?.map((item, index) => {
              return (
                <div key={index} className="img_box">
                  <img src={item} alt="" />
                </div>
              )
            })
          }
        </Carousel>
        <div className="price">
          <span className="now">{currencyIcon}{goodDetail.price}</span>
          {goodDetail.old_price && <span className="old">{currencyIcon}{goodDetail.old_price}</span>}
        </div>
      </div>

      <div className="nametext">
        <span className="title">{goodDetail.name}</span>
        <div className="tags">
          {
            tags.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <svg t="1731487956749" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1470" width="200" height="200">
                    <path
                      d="M512.3 960.5c-247.49999999 0-448.1-200.60000001-448.1-448.1S264.80000001 64.2 512.3 64.2s448.1 200.60000001 448.1 448.1-200.6 448.2-448.1 448.2z m252.6-587.1c-8.8-8.7-22.9-8.70000001-31.7 0L463.8 642.8 321.2 500.2c-8.8-8.8-22.89999999-8.8-31.7 0-8.7 8.8-8.70000001 22.9 0 31.7L448 690.3c8.8 8.7 22.9 8.70000001 31.7 0l285.2-285.2c8.7-8.8 8.7-23 0-31.7z"
                      fill="#d81e06" p-id="1471"
                    ></path>
                  </svg>
                  <span>{item}</span>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="content" dangerouslySetInnerHTML={{ __html: goodDetail.context }}>
      </div>

      <div className="comment">
        <div className="comment_header">最新評價</div>
        <Comment loop={true} goods_id={goodDetail.id} height="300px" />
      </div>

      <div className="create_order">
        <div className="create_header">在線快速訂購</div>
        <div className="select_spec">
          {
            goodDetail.specifications && goodDetail.specifications?.map((item, index) => (
              <div className={goods.id === item.id ? 'item active' : 'item'} key={item.id} onClick={() => updateGoods(item)}>
                <div className="img_box">
                  <img src={item.logo} alt={item.name} />
                </div>
                <div className="name">
                  <span>{item.name}</span>
                </div>
              </div>
            ))
          }
        </div>
        <div className="select_num">
          数量：
          <div className="remove" onClick={() => handleUpdateNum('remove')}>-</div>
          <div className="context">{num}</div>
          <div className="add" onClick={() => handleUpdateNum('add')}>+</div>
        </div>

        <div className="selected_menu">
          <div className="selected_item">
            <div className="selected_header">商品圖片</div>
            <div className="selected_logo">
              <div className="img_box">
                <img src={goods?.logo} alt={goods?.name} />
              </div>
            </div>
          </div>
          <div className="selected_item">
            <div className="selected_header">已選</div>
            <div className="content">{goods?.name} * {num}</div>
          </div>
        </div>

        <div className="form">
          <Spin spinning={loading}>
            <Form
              form={form}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              initialValues={{ pay_type, send_type }}
              autoComplete="off"
              onFinish={handleCreateOrder}
            >
              <Form.Item
                label="付款方式"
                name="pay_type"
                rules={[{ required: true, message: '请选择付款方式!' }]}
              >
                <Radio.Group onChange={e => updatePayType(e.target.value)}>
                  {props.pay_list.map(item => <Radio value={item} key={item}>{item}</Radio>)}
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="物流方式"
                name="send_type"
                rules={[{ required: true, message: '请选择物流方式!', }]}
              >
                <Radio.Group onChange={e => updateSendType(e.target.value)}>
                  {props.send_list.map(item => <Radio value={item} key={item}>{item}</Radio>)}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="姓名" name="name" rules={[{ required: true, message: '请输入姓名' }]}>
                <Input placeholder="请输入姓名" />
              </Form.Item>

              <Form.Item label="手機" name="phone" rules={[{ required: true, message: '请输入手機' }]}>
                <Input placeholder="请输入手機" />
              </Form.Item>

              <Form.Item
                label="地区" name="areas" rules={[
                {
                  required: true,
                  validator: (rules, value, callback) => {
                    const map = [selectedCountry, selectedState, selectedCity, selectedRegion].filter(Boolean)
                    if (map.length < 1) {
                      callback(new Error("请正确选择地区"))
                    } else {
                      callback()
                    }
                  }
                }
              ]}
              >

                <div className="areas_box">
                  <Select
                    showSearch
                    filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                    options={props.countryList}
                    placeholder="请选择国家" onChange={updateSelectedCountry} value={selectedCountry}
                  />
                  <Select showSearch options={stateList} filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())} placeholder="请选择省份" onChange={updateSelectedState} value={selectedState} />
                  <Select showSearch options={cityList} filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())} placeholder="请选择城市" onChange={updateSelectedCity} value={selectedCity} />
                  <Select showSearch options={regionList} filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())} placeholder="请选择地区" onChange={updateSelectedRegion} value={selectedRegion} />
                </div>

              </Form.Item>

              <Form.Item label="详细地址" name="address" rules={[{ required: true, message: '请输入详细地址' }]}>
                <Input placeholder="请输入详细地址，如：某市某区...号" />
              </Form.Item>

              <Form.Item label="楼层/门牌号" name="house_number" rules={[{ required: true, message: '请输入楼层/门牌号' }]}>
                <Input placeholder="楼层/门牌号" />
              </Form.Item>

              <Form.Item label="留言" name="messages">
                <Input.TextArea placeholder="请输入您的留言内容" />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <div className="price_text">
                  <div className="yuan">原价：{currencyIcon}{goodDetail.old_price || goodDetail.price * num}元</div>
                  <div className="goods_price">商品合计：{currencyIcon}{goodDetail.price * num}元</div>
                  <div className="text">信息填写完成，注意点击提交按钮</div>

                  <Button danger type="primary" htmlType="submit" className="submit_btn" loading={loading}>
                    立即提交订单
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Spin>

        </div>
      </div>

      <div className="comment goods_list">
        <div className="comment_header">推荐产品</div>

        <div className="list">
          {goodsList.map(item => <GoodBox {...item} key={item.id} />)}
        </div>

      </div>

      <div className="goods_detail_footer">
        <Collapse accordion items={footer} />
      </div>

    </Container>
  )
}