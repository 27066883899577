import styled from "styled-components";

export const FileList = styled.div`
  display: flex;
  gap: 10px;

  .img_box{
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 3px;
    cursor: pointer;
    border: 1px dashed #ccc;;
    border-radius: 5px;
    &:hover{
      border: 1px dashed #4096ff;
    }
    .delete{
      --size:20px;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      background: red;
      position: absolute;
      right: calc(var(--size) / 2 * -1);
      top: calc(var(--size) / 2 * -1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
`