import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from 'react-router-dom'
import { Content } from "./style.js"
import { Form, Modal, Input, Button } from "antd"
import { getCategorizeList, getCategorizeDetail } from "../../../../api/categorize.js"

export default (props) => {

  const [menu, updateMenu] = useState([])
  const [boxClass, updateClass] = useState(props.fixedClass || "")
  const [phoneMenu, updatePhoneMenu] = useState(false)
  const [indexText, updateIndexText] = useState("首页")
  const [activeName, updateActiveName] = useState("首页")
  const [search, updateSearch] = useState("")
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    getCategorizeList({ page: 1, page_size: 99999, is_show: 1 }).then(res => {
      updateMenu(res.data.list)
    })

    const handleScroll = (e) => {
      const { scrollY } = window
      scrollY > 30 ? updateClass("fixed") : updateClass("")
    };

    let config = window.localStorage.getItem("config")
    config = JSON.parse(config)
    const language = config.find(item => item.name === "language")
    if (language?.value === "en-US") updateIndexText("index")

    if (!props.fixedClass) {
      // 添加滚动事件监听器
      window.addEventListener('scroll', handleScroll);

      // 返回一个清除函数，用于在组件卸载时移除监听器
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [])

  useEffect(() => {
    if (!id) return
    if (id === 'search') return
    getCategorizeDetail(id).then(res => {
      updateActiveName(res.data.name)
    })
  }, [id]);

  const handlePhoneShowMenu = () => {
    updatePhoneMenu(!phoneMenu)
  }

  const showSearchInput = () => {
    updateSearch(true)
  }

  const searcGoods = (values) => {
    history.push(`/products/search?name=${values.name}`)
    updateSearch(false)
  }

  return (
    <>
      <Modal title="搜索商品" open={search} onCancel={() => updateSearch(false)} footer={null} style={{ zIndex: 99999999 }}>
        <Form onFinish={searcGoods} style={{ padding: '20px 0' }}>
          <Form.Item name="name">
            <Input placeholder="请输入商品名称" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">搜索</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Content className={boxClass}>
        <div className="search">
          <div className="search_icon" onClick={showSearchInput}>
            <svg t="1730777012323" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="4296" width="200" height="200">
              <path
                d="M955.069071 864.311021 740.015134 649.258107c-3.752464-3.751441-8.841366-5.860475-14.149255-5.860475-5.306866 0-10.395768 2.108011-14.149255 5.860475l-16.692171 16.692171-38.34226-38.34226c53.03796-59.810201 85.298711-138.442072 85.298711-224.478588 0-186.774871-151.952784-338.727655-338.727655-338.727655S64.527642 216.35456 64.527642 403.12943c0 186.775894 151.952784 338.728678 338.727655 338.728678 86.36909 0 165.276231-32.510438 225.170343-85.913718l38.303374 38.303374-17.34504 17.34504c-7.812943 7.813966-7.812943 20.48352 0 28.297486l215.051891 215.052914c3.753487 3.751441 8.841366 5.860475 14.149255 5.860475 5.306866 0 10.395768-2.108011 14.149255-5.860475l62.334697-62.334697C962.883037 884.794541 962.883037 872.124987 955.069071 864.311021zM104.546078 403.12943c0-164.709319 133.9999-298.709219 298.709219-298.709219s298.709219 133.9999 298.709219 298.709219S567.964616 701.839673 403.255297 701.839673 104.546078 567.838749 104.546078 403.12943zM878.585119 912.496463 691.829691 725.741036l34.036187-34.036187 186.755428 186.755428L878.585119 912.496463z"
                fill="#272636" p-id="4297"></path>
            </svg>
          </div>

          <div className="menu_btn" onClick={handlePhoneShowMenu}>
            <svg t="1730857043824" className="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="4262" width="200" height="200">
              <path
                d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zM904 784H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zM904 472H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"
                p-id="4263"></path>
            </svg>
          </div>
        </div>
        <div className="context">

          <div className="img_box">
            <Link to="/">
              <img src="https://d3mnseao6kv0r1.cloudfront.net/784c665a539c42e7ba965a929f835100.jpg?x-oss-process=style/webp" alt="" />
            </Link>

          </div>

          <div className="menu">
            <div className="item">
              <Link to="/"><span>{indexText}</span></Link>
            </div>
            {
              menu.map(item => {
                return <div className="item" key={item.id}>
                  <Link to={`/products/${item.id}`}><span className={item.name === activeName ? 'active' : ''}>{item.name}</span></Link>
                </div>
              })
            }
          </div>
        </div>

        <div className={phoneMenu ? 'phone_menu show' : 'phone_menu'}>
          <div className="content">
            {
              menu.map(item => {
                return <div className="item" key={item.id}>
                  <Link to={`/products/${item.id}`} onClick={() => updatePhoneMenu(false)}><span className={item.name === activeName ? 'active' : ''}>{item.name}</span></Link>
                </div>
              })
            }
          </div>
          <div className="mark" onClick={handlePhoneShowMenu}></div>
        </div>

      </Content>
    </>
  )
}