import request from "../tool/request"

const baseUrl = '/api/goods'

/**
 * 获取商品列表
 * @param {*} params
 * @returns
 */
export const getGoodsList = (params) => {
  return request({ url: `${baseUrl}/`, method: 'get', params })
}

export const getGoodsListAdmin = (params) => {
  return request({ url: `${baseUrl}/list`, method: 'get', params })
}

/**
 * 获取商品详情
 * @param {*} id
 * @returns
 */
export const getGoodsDetail = (id) => {
  return request({ url: `${baseUrl}/${id}`, method: 'get' })
}

/**
 * 获取商品详情
 * @param {*} id
 * @returns
 */
export const getGoodsDetailAdmin = (id) => {
  return request({ url: `${baseUrl}/admin/${id}`, method: 'get' })
}


/**
 * 创建商品
 * @param {*} data
 * @returns
 */
export const createGoods = (data) => {
  return request({ url: `${baseUrl}/`, method: 'post', data })
}

/**
 * 更新商品信息
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateGoods = (id, data) => {
  return request({ url: `${baseUrl}/${id}`, method: 'put', data })
}

/**
 * 删除商品
 * @param {*} id
 * @returns
 */
export const deleteGoods = (id) => {
  return request({ url: `${baseUrl}/${id}`, method: 'delete', data: {} })
}

export const updateGoodsSpec = (id, data) => {
  return request({ url: `${baseUrl}/addSpec/${id}`, method: 'patch', data })
}

export const exportExcelApi = (ids) => {
  return request({ url: `${baseUrl}/export_excel`, method: 'get', params: { ids }, responseType: 'blob' })
}

export const getPayConfigById = (id) => {
  return request({ url: `${baseUrl}/pay_config/${id}`, method: 'get' })
}