import styled from 'styled-components'

export const Container = styled.div`
  .item{
    --size:40px;
    margin-bottom: 16px;
    cursor: pointer;
    .img_box{
      width: var(--size);
      height: var(--size);
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
      img{
        width: var(--size);
        height: var(--size);
        object-fit: cover;
      }
    }
  }

  .ant-float-btn-group{
    @media screen and (max-width: 999px) {
      inset-inline-end:24px !important;
    }
  }
`