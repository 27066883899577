import request from "../tool/request";

const baseUrl = "/api/users"

export const login = (data) => {
  return request({ url: `${baseUrl}/login`, method: "post", data });
}

export const getUserInfo = () => {
  return request({ url: `${baseUrl}/`, method: "get" });
}

export const getUserList = (params) => {
  return request({ url: `${baseUrl}/list`, method: "get", params });
}

export const register = (data) => {
  return request({ url: `${baseUrl}/register`, method: "post", data });
}

export const deleteUserApi = (id) => {
  return request({ url: `${baseUrl}/${id}`, method: "delete", data: {} });
}

export const updateExpiresApi = (id, data) => {
  return request({ url: `${baseUrl}/updateExpires/${id}`, method: "post", data });
}

export const resetPasswordApi = (id, data) => {
  return request({ url: `${baseUrl}/reset/${id}`, method: "post", data });
}

export const patchTemplate = (template_id) => {
  return request({ url: `${baseUrl}/select_template`, method: "patch", data: { template_id } });
}