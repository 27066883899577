import React from "react";
import { Link } from "react-router-dom";
import currencyList from "../../../goods/currency"
import { Box } from "./style"

export default (props) => {
  return (
    <Link to={`/detail/${props.id}`}>
      <Box>
        <div className="item">
          <div className="img_box">
            <img src={props.logo} alt={props.name} />
          </div>
          <div className="text">
            <span className="name">{props.name}</span>
            <span className="price">{currencyList.find(item => item.value === props.currency)?.icon || "￥"}{props.price}</span>
          </div>
        </div>
      </Box>
    </Link>
  )
}