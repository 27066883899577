import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Menu from './component/menu/menu.jsx'
import Login from './pages/login/index.jsx'
import IsLogin from './component/login/is_login.jsx'
import Article from "./pages/client/article/index.jsx"
import HashSearch from "./pages/hashSearch";
import Home from "./pages/client/home"
import Products from "./pages/client/products"
import ProductDetail from "./pages/client/detail/"

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <IsLogin />
        <Switch>
          <Route exact path="/login" component={Login}></Route>
          <Route path="/home" component={Menu} />
          <Route path="/products/:id" component={Products}></Route>
          <Route path="/article/:id" component={Article}></Route>
          <Route path="/detail/:id" component={ProductDetail}></Route>
          <Route path="/hashSearch/:hash?" component={HashSearch}></Route>
          <Route exact path="/" component={Home}></Route>
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
