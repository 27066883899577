import React, {Component} from "react";
import {Button, Form, Input, message, Modal, Switch, Table} from "antd";
import moment from "moment";

import {Header} from "../goods/style";
import {FormBox} from "./style"
import {getCategorizeList, deleteCategorize, addCategorize, updateCategorize, patchCategorizeShow} from "../../api/categorize";
import {WarningOutlined} from '@ant-design/icons';


const { Search } = Input;
const { confirm } = Modal;


export default class Column extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      list: [],
      loading: true,
      openCreate: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      submitState: false,
      formData: { name: "" },
      testTitle: ''
    }

    this.columns = [
      { title: "分类名称", dataIndex: "name", key: "name", align: "center" },
      {
        title: "是否显示",
        align: "center",
        render: (item) => <Switch
          checkedChildren="显示" unCheckedChildren="隐藏" checked={item.is_show}
          onChange={(val) => this.changeIsShow(val, item)}
        />
      },
      { title: "排序", dataIndex: "sort", key: "sort", align: "center" },
      { title: "创建时间", dataIndex: "ctime", key: "ctime", align: "center" },
      {
        title: "操作",
        dataIndex: "id",
        render: (text, record) => (
          <>
            <Button type="primary" onClick={() => this.handleEditColumn(record)}>编辑</Button>
            &nbsp;&nbsp;
            <Button type="primary" danger onClick={() => this.handleDeleteColumn(text)}>删除</Button>
          </>
        ),
      },
    ];

    this.setTitle = this.setTitle.bind(this)
    this.getColumnList = this.getColumnList.bind(this)
    this.handleEditColumn = this.handleEditColumn.bind(this)
    this.closeCreateColumn = this.closeCreateColumn.bind(this)
    this.handleFinishForm = this.handleFinishForm.bind(this)
    this.handleChangeTable = this.handleChangeTable.bind(this)
    this.handleDeleteColumn = this.handleDeleteColumn.bind(this)
  }

  componentDidMount () {
    this.getColumnList()
  }

  changeIsShow = (val, column) => {
    console.log(val, column)
    this.setState({ loading: true })
    patchCategorizeShow(column.id, { is_show: val ? 1 : 0 }).then(() => {
      message.success("操作成功");
      this.getColumnList()
    }).catch(() => {
      this.setState({ loading: true })
    })
  }

  handleDeleteColumn (id) {
    confirm({
      icon: <WarningOutlined />,
      content: `确定要删除此分类吗？删除后将不可恢复！`,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        this.setState({ loading: true })
        deleteCategorize(id).then(res => {
          message.success("操作成功");
          this.getColumnList()
        }).finally(() => {
          this.setState({ loading: false })
        })
      }
    });
  }


  handleFinishForm (values) {
    const { formData } = this.state
    this.setState({ submitState: true })
    if (formData.id) {
      updateCategorize(formData.id, values).then(() => {
        message.success("修改成功")
        this.getColumnList()
        this.setState({ openCreate: false, formData: { name: "" } })
      }).finally(() => {
        this.setState({ submitState: false })
      })
      return
    }

    addCategorize(values).then(res => {
      message.success("操作成功");
      this.getColumnList()
      this.setState({ openCreate: false, formData: { name: "" } })
    }).finally(() => {
      this.setState({ submitState: false })
    })
  }

  handleEditColumn (rowData) {
    this.setState({ openCreate: true, formData: rowData })
  }

  setTitle (name) {
    this.setState({ name })
  }

  getColumnList (page = 1) {
    const { pagination, name } = this.state
    const params = { page, length: pagination.pageSize }
    if (name) params['name'] = name
    this.setState({ loading: true })

    getCategorizeList(params).then(res => {
      const { list, total } = res.data

      this.setState({
        list: list.map(item => {
          item.key = item.id
          item.ctime = moment(item.ctime * 1000).format("YYYY-MM-DD HH:mm:ss")
          return item
        }),
        pagination: { ...pagination, total, current: page }
      })
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  closeCreateColumn () {
    this.setState({ openCreate: false, formData: { name: '', } })
  }

  handleChangeTable (page) {
    this.getColumnList(page.current)
  }

  render () {
    const { name, formData, list, loading, pagination, openCreate } = this.state
    return (
      <div>
        <div>
          <Header>
            <div className="search">
              <Search
                placeholder="输入分类名称" value={name} onChange={e => this.setTitle(e.target.value)} enterButton
                onSearch={() => this.getColumnList(1)}
              />
            </div>
            <div className="add">
              <Button type="primary" onClick={() => this.setState({ openCreate: true })}>添加</Button>
            </div>
          </Header>

          <Table dataSource={list} columns={this.columns} loading={loading} pagination={pagination} onChange={this.handleChangeTable} />
        </div>

        <Modal title={formData.id ? '修改分类' : '新增分类'} open={openCreate} onCancel={this.closeCreateColumn} footer={[]} destroyOnClose={true}>
          <FormBox>

            <Form name="formData" labelCol={{ span: 6 }} labelAlign="right" wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }} onFinish={this.handleFinishForm}>

              <Form.Item label="分类名称" name="name" rules={[{ required: true, message: '分类名称不能为空' }]} initialValue={this.state.formData.name}>
                <Input />
              </Form.Item>

              <Form.Item label="排序" name="sort" rules={[{ required: true, message: '排序不能为空' }]} initialValue={this.state.formData.sort}>
                <Input />
              </Form.Item>


              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={this.state.submitState}>提交</Button>
              </Form.Item>

            </Form>
          </FormBox>
        </Modal>

      </div>
    );
  }
}
