import {useState, useEffect} from "react"
import {Spin} from "antd"
import {useParams} from "react-router-dom"

import DefaultTemplate from "./template/default"
import Two from "./template/two"

import {getGoodsDetail, getPayConfigById} from "../../../api/goods"
import {getCityListByGoods} from "../../../api/location"

export default () => {

  const { id } = useParams()

  const [detail, initDetail] = useState({})
  const [countryList, updateCountryList] = useState([])
  const [send_list, updateSendList] = useState([])
  const [pay_list, updatePayList] = useState([])
  const [affixMenus, initAffixMenus] = useState([])
  const [loading, updateLoading] = useState(true)

  useEffect(async () => {
    updateLoading(true)
    window.scrollTo(0, 0)
    const promises = []

    promises.push(getGoodsDetail(id).then(res => {
      const goods = res.data
      goods.banners = goods.banners?.split(',') || []
      if (!goods.template) goods.template = 1
      initDetail(goods)
    }))

    promises.push(getCityListByGoods(id).then(res => {
      updateCountryList(res.data.map(item => {
        item.key = item.id
        item.value = item.id
        item.label = item.name
        return item
      }))
    }))

    promises.push(getPayConfigById(id).then(res => {
      updatePayList(res.data)
    }))

    Promise.all(promises).finally(() => {
      let config = window.localStorage.getItem('config')
      if (config) config = JSON.parse(config)
      config.map(item => {
        if (item.name === 'send_list') {
          updateSendList(item.value.split(','))
        }  else if (item.name === 'affix_menus') {
          initAffixMenus(JSON.parse(item.value))
        }
      })
      updateLoading(false)
    })
  }, [id])

  const template_map = {
    1: <DefaultTemplate detail={detail} countryList={countryList} send_list={send_list} pay_list={pay_list} affixMenus={affixMenus} />,
    2: <Two detail={detail} countryList={countryList} send_list={send_list} pay_list={pay_list} />
  }

  return (
    <>
    {loading ? <Spin><div style={{ width: '100%', height: '100vh' }}></div></Spin> : template_map[detail.template] || detail.template}
    </>
  )
}