import styled from "styled-components";

export const Container = styled.div`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  
  video{
    max-width: 100%;
  }

  .loop_box{
    position: absolute;
    top: 40px;
    z-index: 9999999;
  }

  .header {
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #966d51;

    span {
      color: #fff;
      font-size: 20px;
    }
  }

  .banners {
    position: relative;
    --height: 600px;
    height: var(--height);
    @media screen and (max-width: 999px) {
      --height: 430px;
    }

    .img_box {
      width: 100%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: var(--height);

      img {
        max-height: 100%;
        object-fit: cover;
      }
    }

    .price {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #f13a4a;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 15px;
      box-sizing: border-box;
      z-index: 99999;
      gap: 10px;

      .now {
        font-size: 30px;
        color: #fff;
        font-weight: bold;
      }
      .old{
        text-decoration: line-through;
        color: #ccc;
        font-size: 20px;
      }
    }
  }

  .nametext {
    .title {
      font-size: 30px;
    }

    .tags {
      display: flex;
      height: 50px;
      align-items: center;
      background-color: #fff4f4;
      padding: 0 15px;
      box-sizing: border-box;
      gap: 15px;

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        --svgSize: 24px;

        svg {
          width: var(--svgSize);
          height: var(--svgSize);
        }
      }
    }
  }

  .describe {
    padding: 30px 0;
  }

  .content {
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  .comment {
    .comment_header {
      height: 50px;
      line-height: 50px;
      background-color: #966d51;
      text-align: center;
      color: #fff;
    }
  }

  .create_order {
    .create_header {
      height: 50px;
      line-height: 50px;
      background-color: #966d51;
      text-align: center;
      color: #fff;
    }

    .select_spec {
      display: flex;
      flex-wrap: wrap;
      gap: 30px 5%;
      padding: 20px 0;

      .item {
        width: 30%;

        &.active {
          .img_box {
            border: 5px solid red;
          }

          .name {
            span {
              color: red;
            }
          }
        }

        .img_box {
          width: 100%;
          height: 100%;
          max-height: 300px;
          box-sizing: border-box;
          transition: all .3s;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          text-align: center;
          padding: 5px 0 15px;
          transition: all .3s;
        }
      }
    }

    .select_num {
      display: flex;
      gap: 10px;
      user-select: none;
      padding: 15px 0;

      .remove, .add, .context {
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .remove, .add {
        background-color: #777777;
        color: #fff;
      }
    }

    .selected_menu {
      display: grid;
      grid-template-columns: 1fr 2fr;

      .selected_item {
        border: 1px solid #ccc;
        margin-left: -1px;
        margin-top: -1px;

        .selected_header {
          text-align: center;
          background-color: #ffe6cc;
          color: #ff8a14;
        }

        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
        }

        .selected_logo {
          display: flex;
          justify-content: center;
          align-items: center;

          .img_box {
            width: 50px;
            height: 50px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

  }

  .form {
    padding: 15px 0;

    @media screen and (max-width: 999px) {
      padding: 15px;
      box-sizing: border-box;
    }

    .areas_box {
      display: flex;
      gap: 15px;

      @media screen and (max-width: 999px) {
        flex-direction: column;
      }

    }
  }

  .price_text {
    text-align: center;
    color: red;

    .goods_price {
      font-size: 20px;
      font-weight: bold;
    }

    .submit_btn {
      width: 80%;
      margin: 10px 0;
    }
  }

  .goods_list {
    .list {
      padding: 15px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
  }
`;