import styled from "styled-components";

export const Box = styled.div`
  width: 100%;
  height: 100%;
  .item{
      .img_box{
        width: 100%;
        img{
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }
      .text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        gap: 5px;
        .name{
          color: #000;
          font-size: 14px;
        }
        .price{
          color: red;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
`