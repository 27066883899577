import request from "../tool/request";

const baseUrl = '/api/comment'

export const getCommentList = (params) => {
  return request({ url: `${baseUrl}/`, method: 'get', params })
}

export const addComment = (goods_id, data) => {
  return request({ url: `${baseUrl}/${goods_id}`, method: 'post', data })
}
