import React, {useEffect, useState} from "react"
import {Rate, Empty, Pagination, Spin} from "antd"
import moment from "moment"
import {CenterAlign, CommentBox} from "./style"

import {getCommentList} from "../../api/comment"

export default (props) => {

  const [commentList, updateCommentList] = useState([])
  const [page, updatePage] = useState(1)
  const page_size = 3
  const [total, updateTotal] = useState(0)
  const [loading, updateLoading] = useState(true)

  useEffect(() => {
    updateLoading(true)
    handleGetCommentList()
  }, [props.goods_id, page])

  const handleGetCommentList = () => {
    const goods_id = props.goods_id
    if (!goods_id) return
    const params = { goods_id }
    if (!props.loop) {
      params.page = page
      params.page_size = page_size
    }
    getCommentList(params).then(res => {
      const { list, total } = res.data
      updateCommentList(list)
      updateTotal(total)
    }).finally(() => {
      updateLoading(false)
    })
  }


  return (
    <Spin spinning={loading}>
      <CommentBox height={props.height || 'auto'}>
        {
          commentList.length === 0 ? (
              <Empty description="暂无评论"/>
            ) :
            <div className={props.loop ? 'loop' : ''}>
              {
                commentList.map((item) => {
                  return (
                    <div className="comment_item" key={item.id}>
                      <div className="comment_item_name">
                        <span className="name">{item.name}</span>
                        <span className="time">
                    {moment(item.ctime * 1000).format("YYYY-MM-DD")}
                  </span>
                      </div>
                      <div className="comment_item_starts">
                        满意度：
                        <Rate disabled defaultValue={item.starts} style={{ color: "#ee0a24" }}/>
                      </div>
                      <div className="comment_item_describe">{item.describe}</div>
                    </div>
                  )
                })
              }

              {
                props.loop ?
                  commentList.map((item) => {
                    return (
                      <div className="comment_item" key={item.id}>
                        <div className="comment_item_name">
                          <span className="name">{item.name}</span>
                          <span className="time">
                    {moment(item.ctime * 1000).format("YYYY-MM-DD")}
                  </span>
                        </div>
                        <div className="comment_item_starts">
                          满意度：
                          <Rate disabled defaultValue={item.starts} style={{ color: "#ee0a24" }}/>
                        </div>
                        <div className="comment_item_describe">{item.describe}</div>
                      </div>
                    )
                  }) : ""
              }
            </div>
        }
      </CommentBox>
      {!props.loop && <CenterAlign>
        <Pagination defaultCurrent={page} pageSize={page_size} total={total} onChange={updatePage}/>
      </CenterAlign>}
    </Spin>
  )
}