import styled from "styled-components";

export const CBox = styled.div`
  padding: 30px 0;

  .title{
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 10px;
    .prefix,.suffix{
      color: red;
      font-size: 22px;
    }
    span{
      font-size: 30px;
      font-weight: bold;
      position: relative;
    }
  }

  .content{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    .item{
      .img_box{
        width: 100%;
        img{
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }
      .text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        gap: 5px;
        .name{
          color: #000;
          font-size: 14px;
        }
        .price{
          color: red;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  .page{
    text-align: center;
    padding: 30px 0;
  }

  @media screen and (max-width: 1000px) {
    .content{
      padding: 0 20px;
      box-sizing: border-box;
      grid-template-columns: repeat(2, 1fr);
    }
    .title{
      span{
        font-size: 24px;
      }
    }
  }

`;