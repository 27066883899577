/**
 * 负责商品列表与商品添加/编辑组件的切换
 */

import React from 'react'
import {Route} from 'react-router-dom'
import GoodsList from './index.jsx'
import DefaultCreate from '../add_goods/index.jsx'

const Goods = () => {
  return (
    <div>
      <Route path='/home/goods' exact component={GoodsList} />
      <Route path="/home/goods/add_default/:id" component={DefaultCreate} />
    </div>
  )
}

export default Goods