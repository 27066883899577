import styled from "styled-components"

export const SpecificationsBox = styled.div`
  display: flex;
  gap: 10px;

  .add_btn {
    width: 100px;
    height: 100px;
    text-align: center;
    border: 1px dashed #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    gap: 10px;
    user-select: none;

    &:hover {
      border-color: #4facfe;
    }
  }

  .spec_box {
    width: 100px;
    height: 100px;
    text-align: center;
    border: 1px dashed #ccc;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      border-color: #4facfe;
    }

    .img_box {
      width: 80px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`