import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Button, Form, Input, Select } from 'antd'

import { Header, Container } from "./style"
import { getArticleList } from "../../api/article";

export default () => {

  const [loading, updateLoading] = useState(true)
  const [articleList, updateArticleList] = useState([])
  const [total, updateTotal] = useState(0)

  const handleGetList = (params) => {
    getArticleList(params).then(res => {
      const { list, total } = res.data
      updateArticleList(list.map(item => {
        item.key = item.id
        return item
      }))
      updateTotal(total)
      updateLoading(false)
    })
  }

  useEffect(() => {
    handleGetList()
  }, [])

  const typeDict = {
    1: "首页",
    2: "商品详情页"
  }

  const searchList = (values) => {
    updateLoading(true)
    handleGetList(values)
  }
  return (
    <Container>
      <Card title="文章管理">
        <Header>
          <div className="left">
            <Form onFinish={searchList}>
              <Form.Item label="文章标题" name="title" className="inline_box">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="文章分类" name="type" className="inline_box">
                <Select placeholder="请选择文章分类" allowClear>
                  <Select.Option value="1">首页</Select.Option>
                  <Select.Option value="2">商品详情页</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item className="inline_box">
                <Button type="primary" htmlType="submit">搜素</Button>
              </Form.Item>
            </Form>
          </div>
          <Button type="primary">
            <Link to="/home/article/add_default/0">添加文章</Link>
          </Button>
        </Header>

      </Card>
      <br />
      <Card loading={loading}>
        <Table
          dataSource={articleList}
          columns={[
            { title: "标题", dataIndex: "title", key: "title" },
            { title: "分类", dataIndex: "type", render: (type) => typeDict[type] },
            { title: '排序', dataIndex: 'sort', key: 'sort' },
            { title: "创建时间", dataIndex: "ctime", render: (ctime) => moment(ctime * 1000).format("YYYY-MM-DD HH:mm:ss") },
            {
              title: "操作", render: (article) => {
                return (
                  <div>
                    <Button type="primary"><Link to={`/home/article/add_default/${article.id}`}>编辑</Link></Button>
                    <Button style={{ marginLeft: '20px' }} type="primary" danger>删除</Button>
                  </div>
                )
              }
            }
          ]}
        />
      </Card>
    </Container>
  );
}