import styled from "styled-components";

export const Process = styled.div`
  height: 50px;
  background-color: #ccc;

  .context{
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  @media screen and (max-width: 1200px) and (min-width:770px) {
    .context{
      width: 80%;
    }
  }

  @media screen and (max-width: 770px) {
    display: none;
  }
`

export const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  box-sizing: border-box;

  .menu{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px;
    .item{
      &.active{
        a{
          color: red;
        }
      }
      a{
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        color: rgb(102, 102, 102);
        &:hover{
          color: red;
        }
      }
    }
  }

  .content{
    flex: 1;
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .current{
        font-size: 18px;
        font-weight: bold;
      }

      .select_box{
        display: flex;
        gap: 10px;
        .ant-select{
          width: 165px;
        }
      }
    }

    .good_list{
      display: grid;
      grid-template-columns: repeat(4,1fr);
      gap: 10px;
      padding: 10px 0;
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 1200px) and (min-width:770px) {
    width:100%;
    justify-content: center;
    padding: 30px 10%;
    box-sizing: border-box;
    .menu{
      display: none;
    }

    .content{
      .good_list{
        grid-template-columns: repeat(3,1fr);
      }
    }
  }

  @media screen and (max-width: 770px) {
    width:100%;
    justify-content: center;
    padding: 30px 10%;
    box-sizing: border-box;
    .menu{
      display: none;
    }

    .content{
      .header{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
      .good_list{
        grid-template-columns: repeat(2,1fr);
      }
    }
  }
`