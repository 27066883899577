import {useState, useEffect} from "react"
import {Link as LinkDom} from "react-router-dom"
import {Carousel, Rate, Form, Input, Select, Button, Spin, Anchor, Modal, Table} from "antd";
import {createOrder, getOrdersByPhone} from "../../../../api/order"
import {Container, Content, Item, Footer} from "./two.style"
import currencyList from '../../../goods/currency'
import {getStateList, getCityList, getRegionList} from "../../../../api/location"
import {getArticleList} from "../../../../api/article"
import searchIcon from "../../../../images/Snipaste_2024-12-12_19-19-06.png"

const { Link } = Anchor
const returnNumber = () => {
  return Math.floor(Math.random() * 9) + 1
}

const getRandomInt = (min, max) => {
  if (min > max) {
    [min, max] = [max, min];
  }
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const AnchorTitle = () => {
  return <div className="pay_now">
              <svg t="1734002866448" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1534" width="200" height="200"><path d="M808.01792 901.12m-59.04384 0a2.883 2.883 0 1 0 118.08768 0 2.883 2.883 0 1 0-118.08768 0Z" fill="#ffffff" p-id="1535"></path><path
                d="M430.10048 842.07616c-32.54272 0-59.04384 26.39872-59.04384 59.00288 0 32.62464 26.50112 59.0848 59.04384 59.0848 32.58368 0 59.00288-26.46016 59.00288-59.0848C489.10336 868.47488 462.68416 842.07616 430.10048 842.07616z"
                fill="#ffffff"
                p-id="1536"
              ></path><path
                d="M292.29056 640.9216l-58.18368-361.75872C196.95616 66.19136 16.09728 63.83616 14.29504 63.83616L14.1312 120.13568c5.50912 0.1024 135.5776 3.52256 164.31104 168.36608l57.87648 358.58432c2.9696 48.10752 36.18816 139.18208 140.86144 139.18208l553.2672 0 0-56.4224L377.18016 729.84576C300.9536 729.84576 293.2736 652.288 292.29056 640.9216z"
                fill="#ffffff"
                p-id="1537"
              ></path><path
                d="M832.38912 674.16064 476.93824 674.16064c-87.552 0-127.97952-69.46816-137.13408-106.1888-0.47104-2.29376-26.33728-157.34784-43.47904-267.01824-6.26688-39.97696 0.22528-71.5776 19.31264-93.88032 17.42848-20.39808 43.8272-31.15008 76.32896-31.15008 14.2336 0 24.30976 2.1504 24.30976 2.1504 1.80224 0.12288 407.90016 50.09408 500.16256 50.09408 39.99744 0 68.97664 12.1856 86.15936 36.18816 25.41568 35.55328 11.30496 79.95392 9.60512 84.8896l-43.49952 205.4144C966.84032 561.50016 933.15072 674.16064 832.38912 674.16064zM391.9872 235.86816c-8.23296 0-23.22432 1.31072-30.78144 10.1376-6.98368 8.15104-8.99072 24.3712-5.65248 45.6704 17.12128 109.42464 42.9056 264.11008 42.9056 264.11008 1.1264 3.72736 17.408 58.42944 78.45888 58.42944l355.45088 0c56.17664 0 78.00832-73.40032 78.2336-74.1376l43.95008-206.78656c2.94912-9.70752 5.28384-25.84576-0.79872-34.18112-5.16096-7.08608-18.41152-10.97728-37.29408-10.97728-95.92832 0-492.9536-48.8448-509.80864-50.93376C403.6608 236.66688 398.70464 235.86816 391.9872 235.86816z"
                fill="#ffffff"
                p-id="1538"
              ></path></svg>
              <span>立即购买</span>
            </div>
}

const getRandomLetter = (caseType = 'any') => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // 根据caseType参数选择字符集
  const letters = caseType === 'lower' ? lowerCaseLetters :
    caseType === 'upper' ? upperCaseLetters :
      lowerCaseLetters + upperCaseLetters;

  // 获取随机索引
  const randomIndex = Math.floor(Math.random() * letters.length);

  // 返回随机索引处的字符
  return letters[randomIndex];
}

export default (props) => {
  const [currencyIcon, updateCurrencyIcon] = useState("")
  const [selectSpecifications, setSelectSpecifications] = useState('')
  const pay_list = ["货到付款", "PayPal付款"]
  const [send_type, updateSendType] = useState("")
  const [pay_type, updatePayType] = useState("货到付款")

  const [stateList, updateStateList] = useState([])
  const [cityList, updateCityList] = useState([])
  const [regionList, updateRegionList] = useState([])

  const [loading, updateLoading] = useState(false)

  const [outList, updateOutList] = useState([])

  const [searchOrderModal, updateSearchOrderModal] = useState(false)
  const [searchLoading, updateSearchLoaidng] = useState(false)
  const [orderList, updateOrderList] = useState([])
  const [tags, updateTags] = useState([])

  const [article, updateArticle] = useState([])

  useEffect(() => {
    const newList = []
    for (let i = 0; i < 100; i++) {
      newList.push({
        phone: `1${new Array(3).fill(0).map(() => returnNumber()).join('')}**${new Array(3).fill(0).map(() => returnNumber()).join('')}`,
        address: `${getRandomLetter("upper")}**${getRandomLetter(Math.random() < 0.5 ? 'lower' : 'upper')}`,
        key: Math.random() + new Date().getTime()
      })
    }
    updateOutList(newList)

  }, []);

  useEffect(() => {
    if (!props.detail) return

    const { currency, context, specifications, template_data } = props.detail

    getArticleList({ type: 2, template: template_data.id, page_size: 6 }).then(res => {
      updateArticle(res.data.list)
    })


    if (currency) {
      updateCurrencyIcon(currencyList.find(item => item.value === currency)?.icon || "￥")
    }
    if (context) {
      const videos = document.querySelectorAll("video")
      for (let item of videos) {
        item.setAttribute("playsInline", true)
      }
    }
    if (specifications?.length >= 1) {
      setSelectSpecifications(specifications[0].id)
    }
    const { goods_tags } = template_data
    updateTags(goods_tags?.split(',') || [])
  }, [props.detail])

  // useEffect(() => {
  //   if (props.pay_list?.length >= 1) {
  //     updatePayType(props.pay_list[0])
  //   }
  // }, [props.pay_list]);

  useEffect(() => {
    if (props.send_list?.length >= 1) {
      updateSendType(props.send_list[0])
    }
  }, [props.send_list]);

  const submitForm = (values) => {
    updateLoading(true)
    const areas = [values.country, values.state, values.city, values.region].filter(Boolean).join(',')
    createOrder({ ...values, goods_id: props.detail.id, specifications: selectSpecifications, num: 1, pay_type, areas }).then(res => {
      window.location.href = res.data.data.pay_url
    }).catch(() => {
      updateLoading(false)
    })
  }

  const changeCountry = (value) => {
    getStateList(value).then(res => updateStateList(res.data.map(item => {
      item.key = item.id
      item.value = item.id
      item.label = item.name
      return item
    })))
  }

  const changeState = (value) => {
    getCityList(value).then(res => updateCityList(res.data.map(item => {
      item.key = item.id
      item.value = item.id
      item.label = item.name
      return item
    })))
  }

  const changeCity = (value) => {
    getRegionList(value).then(res => updateRegionList(res.data.map(item => {
      item.key = item.id
      item.value = item.id
      item.label = item.name
      return item
    })))
  }

  const searchOrder = (values) => {
    updateSearchLoaidng(true)
    updateOrderList([])
    getOrdersByPhone(values.phone).then(res => {
      updateOrderList(res.data)
    }).finally(() => {
      updateSearchLoaidng(false)
    })
  }

  const closeSearchModal = () => {
    updateSearchOrderModal(false)
    updateOrderList([])
  }

  const cloumns = [
    { key: '1', title: '商品名称', dataIndex: 'name' },
    { key: '2', title: '订单状态', dataIndex: 'status' }
  ]
  return (
    <Spin spinning={loading}>
      <Container>
        <Content>
          <Carousel autoplay>
            {props.detail.banners?.map((item, index) => <div key={index} className="img_box"><img src={item} alt={props.detail.name} /></div>)}
          </Carousel>

          <div className="name_text">
            <div className="describe">{props.detail.describe}</div>
            <div className="price">
              <span className="price_show">{currencyIcon}{props.detail.price}</span>
              {props.detail.old_price && <span className="old_price">{currencyIcon}{props.detail.old_price}</span>}
            </div>
            
            <div className="rate">
              <Rate disabled defaultValue={5} style={{ fontSize: '12px' }} />
              <div className="tags">
                {
                  tags.map(item => <span key={item}>{item}</span>)
                }
              </div>
            </div>
            <div className="nums">
              <span className="label">已售数:</span>
              <span className="value">{getRandomInt(3452,5000)}人已经购买，剩余{getRandomInt(1,100)}件 售完即止</span>
            </div>
          </div>

          <div className="silde_box">特徵和細節</div>
          <div className="context" dangerouslySetInnerHTML={{ __html: props.detail.context }}></div>

          <div className="silde_box">訂單詳情</div>

          <div className="select_sku" id="pay">
            <div className="title">
              <span>選擇套餐</span>
            </div>
            <div className="list">
              {props.detail?.specifications?.map((item, index) => (
                <div key={index} className={selectSpecifications === item.id ? "item active" : "item"} onClick={() => setSelectSpecifications(item.id)}>
                  {item.logo && (<div className="img_box">
                    <img src={item.logo} alt={item.name} />
                  </div>)}

                  <div className="name">{item.name}</div>
                </div>
              ))}
            </div>
            <div className="total_price">
              <span>總價：{currencyIcon}{props.detail.price}</span>
            </div>

            <div className="select_pay_box">
              {pay_list.map((item) => <div key={item} className={pay_type === item ? 'item active' : 'item'} onClick={() => updatePayType(item)}><span>{item}</span></div>)}
            </div>

            <Form style={{ padding: '20px 0' }} onFinish={submitForm}>
              <Form.Item name="name" label="姓名" rules={[{ required: true, message: '請輸入姓名' }]}>
                <Input placeholder="請輸入姓名" />
              </Form.Item>
              <Form.Item label="电话：" name="phone" rules={[{ required: true, message: '請輸入电话' }]}>
                <Input placeholder="請輸入电话" />
              </Form.Item>

              {/* <div className="select_pay_box" style={{ paddingBottom: '15px' }}>
                {props.send_list?.map((item) => <div key={item} className={send_type === item ? 'item active' : 'item'} onClick={() => updateSendType(item)}><span>{item}</span></div>)}
              </div> */}

              <Form.Item label="国家" name="country" rules={[{ required: true, message: '請选择国家' }]}>
                <Select
                  // showSearch
                  optionFilterProp="children"
                  options={props.countryList}
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  onChange={changeCountry}
                  placeholder="请选择国家"
                />
              </Form.Item>

              <Form.Item label="省份" name="state">
                <Select
                  // showSearch
                  options={stateList}
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  placeholder="请选择省份"
                  onChange={changeState}
                />
              </Form.Item>

              <Form.Item label="城市" name="city">
                <Select
                  // showSearch
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  options={cityList}
                  placeholder="请选择城市"
                  onChange={changeCity}
                />
              </Form.Item>

              <Form.Item label="地区" name="region">
                <Select
                  // showSearch
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  options={regionList}
                  placeholder="请选择地区"
                />
              </Form.Item>

              <Form.Item label="地址" name="address" rules={[{ required: true, message: '请输入详细地址' }]}>
                <Input placeholder="请输入详细地址，如：某市某区...号" />
              </Form.Item>

              <Form.Item label="留言" name="messages">
                <Input.TextArea placeholder="请输入您的留言内容" />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Button type="primary" htmlType="submit" className="submit_btn" loading={loading}>立即提交订单</Button>
              </Form.Item>

            </Form>

          </div>

          <div className="out_box">
            <div className="silde_box">最近銷售</div>
            <Carousel autoplay dotPosition="left" dots={false}>
              {
                outList.map(item => {
                  return (
                    <Item key={item.key}>
                      <span>{item.phone}</span>
                      <span>{item.address}</span>
                      <span>{new Date().getMonth() + 1}-{new Date().getDate()}</span>
                    </Item>
                  )
                })
              }
            </Carousel>
          </div>

          <Footer>
            <div className="article">
              {
                article.map(item => {
                  return (
                    <LinkDom to={`/article/${item.id}`} key={item.id}>
                      <div className="item">
                        <span>{item.title}</span>
                      </div>
                    </LinkDom>
                  )
                })
              }
            </div>
            <div className="tip">
              {props.detail?.template_data?.copyright_text}
            </div>
          </Footer>

          <div className="bar_box">
            <div className="search_order" onClick={updateSearchOrderModal}>
              <img src={searchIcon} alt="" />
              <span>搜索订单资料</span>
            </div>
            <Link href="#pay" title={<AnchorTitle />} style={{ flex: 1 }}></Link>
          </div>

        </Content>
      </Container>

      <Modal open={searchOrderModal} footer={null} title="搜索订单资料" onCancel={closeSearchModal} destroyOnClose>
        <Form style={{ paddingTop: '20px' }} onFinish={searchOrder}>
          <Form.Item name="phone" rules={[{ required: true, message: '请输入手机号' }]}>
            <Input placeholder="请输入手机号" />
          </Form.Item>
          <Form.Item>
            <Button loading={searchLoading} type="primary" htmlType="submit" style={{ background: 'linear-gradient(90deg, #e861f7, #7c57f5)', width: '100%' }}>搜索</Button>
          </Form.Item>
        </Form>
        <div className="order_list">
          <Table dataSource={orderList} columns={cloumns} pagination={false}></Table>
        </div>
      </Modal>
    </Spin>
  )
}