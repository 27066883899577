import request from "../tool/request";

const baseUrl = "/api/config";

export const getConfig = (name) => {
  return request({ url: `${baseUrl}/`, method: "get", params: { name } });
};

export const getConfigClient = (name) => {
  return request({ url: `${baseUrl}/client`, method: "get", params: { name } });
};

export const updateConfig = (data) => {
  return request({ url: `${baseUrl}/`, method: "post", data });
};

export const putConfigOne = (name, value) => {
  return request({ url: `${baseUrl}/${name}`, method: "put", params: { value } });
}