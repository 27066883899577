import request from "../tool/request";

const baseUrl = '/api/specifications'

export const getSpecifications = (params) => request.get(`${baseUrl}/`, params)

export const getSpecificationsDetail = (id) => request.get(`${baseUrl}/${id}`)

export const getSpecificationsByGoodsId = (id) => request.get(`${baseUrl}/goods/${id}`)

export const addSpecifications = (data) => request.post(`${baseUrl}/`, data)

export const updateSpecifications = (id, data) => request.put(`${baseUrl}/${id}`, data)

export const deleteSpecifications = (id) => request.delete(`${baseUrl}/${id}`)