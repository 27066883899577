import styled from "styled-components";

export const Container = styled.div`
  padding-left: 10px;
  
  .slick-list{
    height: 100px !important;
  }

  .slick-slide{
    margin-bottom: 10px;
  }

  .item{
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #33333391;
    padding: 5px;
    border-radius: 4px;
    overflow: hidden;
    .img_box{
      width: 20px;
      height: 20px;
      border-radius: 4px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .card{
      width: 20px;
      height: 20px;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    .name{
      font-size: 14px;
      color: #fff;
    }
  }
`;