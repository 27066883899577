import request from "../tool/request";

const baseUrl = '/api/order'

export const getOrderList = (params) => request.get(`${baseUrl}/`, { params })

export const getOrderDetail = (id) => request.get(`${baseUrl}/${id}`)

export const updateOrder = (data) => request.patch(`${baseUrl}/update_status`, data)

export const deleteOrder = (id) => request.delete(`${baseUrl}/${id}`)

export const createOrder = (data) => request.post(`${baseUrl}/`, data)

export const updateOrderMessageApi = (id, data) => request.patch(`${baseUrl}/update_message/${id}`, data)

export const exportExcel = (params) => request({ url: `${baseUrl}/export_excel`, method: "get", params: { codes: params }, responseType: 'blob' })

export const getOrdersByPhone = (phone) => request.get(`${baseUrl}/phone/${phone}`)