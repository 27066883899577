import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(241, 241, 241);
`;

export const Content = styled.div`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;

  background-color: rgb(255, 255, 255);
  
  video{
    max-width: 100%;
  }

  .img_box {
    width: 100%;
    height: 400px;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .name_text {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    .price{
      
      padding: 5px 0;
      .price_show{
        font-size: 20px;
        background: linear-gradient(90deg, #e861f7, #7c57f5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight:bold;
      }
      .old_price{
        font-size: 18px;
        text-decoration: line-through;
        color: #ccc;
        margin-left: 5px;
      }
    }
    .rate{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tags{
        display: flex;
        gap: 5px;
        span{
          padding: 5px;
          border: 1px solid rgb(182, 98, 246);
          color: rgb(182, 98, 246);
          border-radius: 4px;
        }
      }
    }
    .nums{
      background-color: rgb(244, 244, 244);
      padding: 5px;
      box-sizing: border-box;
      margin-top: 10px;
      .label{
        background: linear-gradient(90deg, #e861f7, #7c57f5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .value{
        background: linear-gradient(90deg, #e861f7, #7c57f5);
        color: #fff;
        padding: 0 10px;
        border-radius: 10px;
        margin-left: 10px;
      }
    }
  }

  .silde_box {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(90deg, #e861f7, #7c57f5);
    color: #fff;
  }

  .context {
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  .select_sku {
    padding: 20px;

    .list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      border-bottom: 1px solid #ccc;

      .item {
        --height: 40px;
        height: var(--height);
        padding: 5px 15px;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
        width: fit-content;
        min-width: 200px;

        &:hover, &.active {
          background: linear-gradient(90deg, #e861f7, #7c57f5);
          color: #fff;
        }


        .img_box {
          width: var(--height);
          height: 100%;
          border-radius: 2px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .total_price {
      text-align: right;
      font-weight: bold;
      color: red;
      font-size: 24px;
      padding-bottom: 15px;
    }

    .select_pay_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding-top: 15px;
      gap: 10px;

      .item {
        flex: 1;
        border: 1px solid #ccc;
        border-radius: 4px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        cursor: pointer;

        &.active, &:hover {
          background: linear-gradient(90deg, #e861f7, #7c57f5);
          color: #fff;
        }
      }
    }

    .submit_btn {
      width: 100%;
      background: linear-gradient(90deg, #e861f7, #7c57f5);
    }

  }

  .out_box {
    .slick-list {
      height: 400px !important;
    }
  }
  
  .bar_box{
    position: sticky;
    bottom: 0;
    height: 40px;
    background: #fff;
    display: flex;
    gap: 10px;
    box-shadow: 4px 0 10px #e861f7;
    .ant-anchor-link{
      flex: 1;
    }
    .search_order{
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: linear-gradient(90deg, #e861f7, #7c57f5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 20px;
      img{
        margin-top: 2px;
      }
    }
    .pay_now{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90deg, #e861f7, #7c57f5);
      cursor: pointer;
      color: #fff;
      font-size: 20px;
      height: 40px;
      svg{
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
  }

`;

export const Item = styled.div`
  height: 30px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
`

export const Footer = styled.div`
  
  background-color: rgb(229, 229, 229);
  padding: 15px;
  
  .article{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item{
      span{
        color: #000;    
      }
    }
    
  }
  .tip{
    text-align:center;
  }
  @media screen and (max-width: 999px) {
    font-size:12px;
  }
`