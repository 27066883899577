import styled from "styled-components";

export const Fbox = styled.div`
  .context {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #ccc;
    padding-top: 30px;

    .text {
      display: flex;
      justify-content: space-between;


      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .li {
        font-size: 14px;
        color: #000;
      }

      .menu, .connect {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .menu {
        .item {
          color: #000;
        }
      }

      .connect {
        .table {
          .row {
            display: flex;
            margin-bottom: 10px;

            .val {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }

    .pay_list {
      padding: 20px 0;
      text-align: center;
      width: 100%;

      img {
        max-width: 100%;
      }
    }

  }

  @media screen and (max-width: 1000px) {
    .text {
      flex-direction: column;
      padding: 0 30px;
      box-sizing: border-box;
      gap: 30px;
    }
  }

`;