import styled from 'styled-components'

export const Container = styled.div`

  .inline_block {
    display: inline-block;
    margin-right: 15px;
  }

  .ant-table-body{
    scrollbar-width: thin;
    scrollbar-color: #eaeaea transparent;
    scrollbar-gutter: stable;
  }
  
`