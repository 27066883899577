import React, {Component} from "react";
import moment from "moment";
import {Header, Content} from "./style";
import {Input, Button, message, Popconfirm, Image, Table, Select, Modal, Form, Rate, Upload} from "antd";
import {getGoodsListAdmin, deleteGoods, exportExcelApi} from "../../api/goods";
import {getCategorizeList} from "../../api/categorize"
import {addComment} from "../../api/comment"
import currencyList from "./currency";
import CommentList from "../../component/comment"

const {Search} = Input;

class ArticleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
      formData: {length: 10, page: 1, key: "", cate: ''},
      loading: true,
      delState: {confirmLoading: false, id: ""},
      pathname: props.location.pathname,
      showAddComment: false,
      pagination: {current: 1, pageSize: 10, total: 0},
      columnList: [],
      goodAddCommentId: "",
      addCommentLoading: false,
      showCommentModel: false,
      showCommentGoods: null,
      loadingShowComment: true,
      selectGoods: []
    };
    this.columns = [
      {title: "商品名称", align: 'center', dataIndex: 'name', key: 'name', width: '200px', fixed: 'left'},
      {title: '封面', align: 'center', dataIndex: 'logo', render: (logo) => <Image width={80} height={80} src={logo}/>},
      {title: '商品价格', dataIndex: 'price', key: 'price', align: 'center', width: '200px'},
      {title: '商品原价', dataIndex: 'old_price', key: 'old_price', align: 'center', width: '200px'},
      {
        title: '商品货币',
        dataIndex: '',
        align: 'center',
        render: (item) => currencyList.find(currency => currency.value === item?.currency)?.label || "人民币 (CNY)",
        width: '200px'
      },
      {title: '商品描述', dataIndex: 'describe', key: 'describe', align: 'center', width: '200px'},
      {title: '商品分类', dataIndex: 'cate_name', key: 'cate_name', align: 'center', width: '200px'},
      {title: '创建时间', dataIndex: 'ctime', key: 'ctime', align: 'center', width: '200px'},
      {title: '最后一次操作时间', dataIndex: 'updateTime', key: 'updateTime', align: 'center', width: '200px'},
      {
        title: "操作", align: 'center', width: '200px', dataIndex: '', fixed: 'right', render: (item) => {
          return (
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
              <Button type="primary" onClick={() => this.handleShowDetail(item)}>查看商品</Button>
              <Button type="primary" onClick={() => this.handleGoToArticleSet(item)}>编辑</Button>
              <Button type="primary" onClick={() => this.handleAddcommmit(item)}>添加评论</Button>
              <Popconfirm
                title="确定要删除吗？"
                okText="确定"
                placement="topRight"
                okButtonProps={{loading: this.state.delState.confirmLoading}}
                visible={item.show}
                cancelText="我再想想"
                onConfirm={() => this.handleConfirmDel(item.id)}
                onCancel={() => this.handleHideConfirm(item.id)}
              >
                <Button type="primary" danger onClick={() => this.handleDelArticleItem(item.id)}>删除</Button>
              </Popconfirm>
              <Button type="primary" onClick={() => this.handleShowComment(item)}>查看评论</Button>

            </div>
          )
        }
      }
    ]
    this.changeSelectColumn = this.changeSelectColumn.bind(this)
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectGoods,
      onChange: (selectedRowKeys) => this.setState({selectGoods: selectedRowKeys})
    };
    return (
      <Content>
        <div>
          <Header>
            <div className="form_box">
              <Select style={{width: 200}} allowClear={true} placeholder="栏目选择" onChange={this.changeSelectColumn}
                      options={this.state.columnList}></Select>
              <div className="search">
                <Search placeholder="输入商品名称" value={this.state.formData.key} onSearch={this.handleSearchArticle}
                        onChange={this.handleFormKeyChange} enterButton/>
              </div>
            </div>
            <div className="add">
              <Button type="primary" onClick={this.addArticleSelect}>添加</Button>
              <Upload action="/api/goods/upload_excel" name='file' accept=".csv" showUploadList={false}
                      onChange={this.handleUploadChange}>
                <Button type="primary">导入</Button>
              </Upload>

              <Button type="primary" onClick={this.exportExcel}>导出</Button>
            </div>
          </Header>
        </div>
        <Table
          dataSource={this.state.list}
          columns={this.columns}
          loading={this.state.loading}
          pagination={this.state.pagination}
          onChange={this.handleChangeTable}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          scroll={{x: 'max-content'}}
        />

        {/* 添加评论弹窗 */}
        <Modal
          title="添加评论"
          visible={this.state.showAddComment}
          footer={null}
          destroyOnClose={true}
          onCancel={this.handleHideAddComment}
          keyboard={false}
          maskClosable={false}
        >
          <Form labelCol={{span: 5}} wrapperCol={{span: 19}} onFinish={this.handleAddCommit}>
            <Form.Item label="姓名" name="name" rules={[{required: true, message: '请输入姓名'}]}>
              <Input placeholder="请输入姓名"/>
            </Form.Item>
            <Form.Item label="评分" name="starts" rules={[{required: true, message: '请输入评分',}]}>
              <Rate/>
            </Form.Item>
            <Form.Item label="评论内容" name="describe" rules={[{required: true, message: '请输入评论内容',}]}>
              <Input.TextArea placeholder="请输入评论内容" maxLength={100}/>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 5, span: 19,}}>
              <Button type="primary" htmlType="submit" loading={this.state.addCommentLoading}>
                提交
              </Button>
            </Form.Item>
          </Form>

        </Modal>

        {/* 查看评论 */}
        <Modal
          title="评论列表"
          visible={this.state.showCommentModel}
          footer={null}
          destroyOnClose={true}
          onCancel={this.handleHideComment}
          keyboard={false}
          maskClosable={false}
          loading={this.state.loadingShowComment}
        >
          <CommentList goods_id={this.state.showCommentGoods?.id}/>
        </Modal>

      </Content>
    );
  }

  handleShowDetail = (good) => {
    window.open(`/detail/${good.id}`)
  }

  handleUploadChange = (file) => {
    if (file.file.status === 'done') {
      // this.setState({ loading: false })
      message.success('上传成功')
      this.handleGetArticleList()
    } else {
      this.setState({loading: true})
    }
  }

  exportExcel = () => {
    const {selectGoods} = this.state
    if (!selectGoods.length) {
      message.error("请选择商品")
      return
    }
    exportExcelApi(selectGoods.join(',')).then(res => {     
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '商品导出.xlsx'); // 设置下载的文件名
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
  }

  componentDidMount = () => {
    this.handleGetArticleList();
    this.handleGetColumnList()
  };

  handleHideComment = () => {
    this.setState({showCommentModel: false})
  }

  handleShowComment = (goods) => {
    this.setState({showCommentGoods: goods, showCommentModel: true})
  }

  handleAddCommit = (values) => {
    const {goodAddCommentId} = this.state
    if (!goodAddCommentId) {
      message.error("请选择商品")
      return
    }
    this.setState({addCommentLoading: true})
    addComment(goodAddCommentId, values).then(res => {
      message.success("添加成功")
      this.handleHideAddComment()
    }).finally(this.setState({addCommentLoading: false}))
  }

  handleHideAddComment = () => {
    this.setState({showAddComment: false, goodAddCommentId: ""})
  }

  handleConfirmDel = (id) => {
    this.setState({loading: true})
    deleteGoods(id).then(() => {
      message.success("删除成功")
      this.handleGetArticleList()
      this.setState({loading: false})
    })
  }

  handleAddcommmit = (goods) => {
    this.setState({showAddComment: true, goodAddCommentId: goods.id})
  }

  changeSelectColumn(value) {
    this.setState({
      formData: {...this.state.formData, cate: value},
      loading: true
    }, () => {
      this.handleGetArticleList()
    })
  }

  handleChangeTable = (page) => {
    this.handleChangePages(page.current)
  }

  // 获取栏目信息
  handleGetColumnList() {
    const params = {page: 1, page_size: 99999}
    getCategorizeList(params).then(res => {
      const {list} = res.data
      this.setState({
        columnList: list.map(item => {
          item.key = item.id
          item.value = item.id
          item.label = item.name
          return item
        }),
      })
    })
  }

  // 选择新建商品类型
  addArticleSelect = () => {
    this.props.history.push("/home/goods/add_default/0")
  };

  // 前往商品编辑
  handleGoToArticleSet = obj => {
    this.props.history.push("/home/goods/add_default/" + obj.id);
  };

  // 分页功能
  handleChangePages = e => {
    const {formData} = this.state;
    formData.page = e;
    this.setState(
      {formData, loading: true},
      () => {
        this.handleGetArticleList();
      }
    );
  };

  // 取消删除
  handleHideConfirm = id => {
    let {list} = this.state;
    list = list.map(item => {
      if (item.id === id) item.show = false;
      return item;
    });
    this.setState({list});
  };


  // 删除商品
  handleDelArticleItem = id => {
    let {list, delState} = this.state;
    list = list.map(item => {
      if (item.id === id) {
        item.show = !item.show;
      } else {
        item.show = false;
      }
      return item;
    });
    delState.id = id;
    this.setState({list, delState});
  };

  // 搜索关键字修改
  handleFormKeyChange = e => {
    const {value} = e.target;
    const {formData} = this.state;
    formData.key = value;
    this.setState({formData});
  };

  // 搜索商品
  handleSearchArticle = () => {
    const {formData} = this.state;
    formData.page = 1;
    this.setState({loading: true, formData},
      () => {
        this.handleGetArticleList();
      }
    );
  };

  // 获取商品列表
  handleGetArticleList = () => {
    getGoodsListAdmin(this.state.formData).then(res => {
      const {list, count: total} = res.data.data;
      this.setState({
        total,
        pagination: {...this.state.pagination, total},
        loading: false,
        list: list.map(item => {
          item.key = item.id
          item.ctime = moment(item.ctime * 1000).format("YYYY-MM-DD HH:mm:ss")
          item.updateTime && (item.updateTime = moment(item.updateTime * 1000).format("YYYY-MM-DD HH:mm:ss"))
          return item
        })
      });
    })
  };
}

export default ArticleList;
