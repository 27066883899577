import React, { useEffect, useState } from "react";
import { Card } from "antd"
import Header from "../component/header";
import Footer from "../component/footer"
import { getArticleDetail } from "../../../api/article"

export default (props) => {
  const [context, updateContext] = useState("")
  const [title,updateTitle] = useState("")

  useEffect(() => {
    getArticleDetail(props.match.params.id).then(res => {
      updateContext(res.data.context)
      updateTitle(res.data.title)
    })
  }, [])

  return (
    <>
      {/*<Header />*/}
      <Card style={{ maxWidth: '1000px', margin: '20px auto' }} title={title}>
        <div dangerouslySetInnerHTML={{ __html: context }}></div>
      </Card>
      {/*<Footer />*/}
    </>
  );
}