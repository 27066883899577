import React, { useEffect, useState } from 'react'
import moment from "moment";
import { Card, Input, Button, Form, Table, Spin, Select, message, Modal } from 'antd'

import { Container } from "./style"
import { getOrderList, updateOrder, updateOrderMessageApi, exportExcel } from '../../api/order'
import currencyList from '../goods/currency'

const statusMap = {
  '-1': '无效订单',
  0: '未支付',
  1: '已支付，待发货',
  2: '已发货',
}

export default () => {

  const [list, updateList] = useState([])
  const [total, updateTotal] = useState(0)
  const [loading, updateLoading] = useState(true)

  const [page, updatePage] = useState(1)
  const [page_size] = useState(10)
  const [form] = Form.useForm()

  const [selectOrder, updateSelectOrder] = useState([])
  const [showUpdateModal, updateUpdateModal] = useState(false)
  const [messageModal, updateMessageModal] = useState(false)

  const columns = [
    { title: '订单号', dataIndex: 'code', key: 'code', align: 'center', fixed: 'left' },
    { title: '商品名称', dataIndex: 'goods_name', key: 'goods_name', align: 'center' },
    { title: '订单状态', key: 'status', align: "center", dataIndex: 'status', width: '100px', render: (status) => statusMap[status] || "未知状态" },
    { title: '商品单价', align: 'center', width: '100px',render:(order) => <span>{currencyList.find(item => item.value === order.currency)?.icon || "￥"}{order.goods_price}</span> },
    { title: '商品数量', dataIndex: 'num', key: 'num', align: 'center', width: '100px' },
    { title: '支付金额', align: 'center', width: '100px',render:(order) => <span>{currencyList.find(item => item.value === order.currency)?.icon || "￥"}{order.pay_price}</span>},
    { title: '支付方式', dataIndex: 'pay_type', key: 'pay_type', align: 'center', width: '100px' },
    { title: '配送方式', dataIndex: 'send_type', key: 'send_type', align: 'center', width: '150px' },
    { title: '收货人', dataIndex: 'name', key: 'name', align: 'center', width: '100px' },
    { title: '收货地址', dataIndex: 'address', key: 'address', align: 'center', width: '250px' },
    { title: '联系电话', dataIndex: 'phone', key: 'phone', align: 'center', width: '100px' },
    { title: '创建时间', key: 'ctime', align: "center", dataIndex: 'ctime', render: (ctime) => moment(ctime * 1000).format("YYYY-MM-DD HH:mm:ss") },
    {
      title: '支付时间', key: 'pay_time', align: "center", dataIndex: 'pay_time', width: '180px', render: (pay_time) => pay_time ? moment(pay_time * 1000).format("YYYY-MM-DD HH:mm:ss") : ''
    },
    { title: '备注', dataIndex: 'messages', key: 'messages', align: 'center', width: '100px' },
    { title: '订单备注', dataIndex: 'order_messages', key: 'order_messages', align: 'center', width: '100px', fixed: 'right' },
  ]

  const handleGetOrderList = (formData) => {
    updateLoading(true)
    getOrderList({ ...formData, page, page_size }).then(res => {
      updateList(res.data.list.map(item => {
        item.key = item.code
        return item
      }))
      updateTotal(res.data.total)
    }).finally(() => {
      updateLoading(false)
    })
  }

  useEffect(() => {
    const values = form.getFieldsValue()
    handleGetOrderList(values)
  }, [page]);

  const showUpdateStatusModal = () => {
    if (!selectOrder.length) {
      message.error("请选择订单")
      return
    }
    updateUpdateModal(true)
  }

  const rowSelection = {
    selectedRowKeys: selectOrder,
    onChange: (selectedRowKeys) => updateSelectOrder(selectedRowKeys)
  };

  const updateOrderStatus = (values) => {
    console.log(values);
    Modal.confirm({
      title: '修改订单状态',
      content: <div>确定要修改订单 <strong> {selectOrder.toString()} </strong> 状态为 {statusMap[values.status]} 吗？</div>,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        updateLoading(true)
        updateOrder({ codes: selectOrder.join(','), status: values.status }).then(() => {
          updateUpdateModal(false)
          updateSelectOrder([])
          handleGetOrderList()
          message.success('修改成功')
        })
      },
    });
  }

  const showUpdateMessageModal = () => {
    if (!selectOrder.length) {
      message.error("请选择订单")
      return
    }
    if (selectOrder.length > 1) {
      message.error("修改订单备注只允许单个修改")
      return
    }
    updateMessageModal(true)
  }

  const updateOrderMessage = (values) => {
    updateLoading(true)
    updateOrderMessageApi(selectOrder[0], values).then(() => {
      message.success("修改成功")
      updateMessageModal(false)
      const values = form.getFieldsValue()
      handleGetOrderList(values)
    }).catch(() => {
      updateLoading(false)
    })
  }

  const exportExcels = () => {
    exportExcel(selectOrder.join(',')).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));      
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '订单导出.xlsx'); // 设置下载的文件名
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
  }


  return (
    <Container>
      <Modal title="修改订单状态" open={showUpdateModal} footer={null} onCancel={() => updateUpdateModal(false)} destroyOnClose>
        <Form
          onFinish={updateOrderStatus}
          style={{ padding: '20px', maxWidth: 600, }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item label="订单状态" name="status" rules={[{ required: true, message: '请选择订单状态！' }]}>
            <Select>
              <Select.Option value="-1">无效订单</Select.Option>
              <Select.Option value="2">已发货</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
            <Button htmlType='submit' type='primary' loading={loading}>提交</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="修改订单备注" open={messageModal} footer={null} onCancel={() => updateMessageModal(false)} destroyOnClose>
        <Form
          onFinish={updateOrderMessage}
          style={{ padding: '20px', maxWidth: 600, }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item label="订单备注" name="order_messages" rules={[{ required: true, message: '请输入订单备注！' }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
            <Button htmlType='submit' type='primary' loading={loading}>提交</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Card>
        <Form onFinish={handleGetOrderList} form={form}>
          <Form.Item name="code" label="订单号" className="inline_block">
            <Input placeholder="请输入订单号" />
          </Form.Item>
          <Form.Item name="goods_name" label="商品名称" className="inline_block">
            <Input placeholder="请输入商品名称" />
          </Form.Item>

          <Form.Item name="status" label="支付状态" className="inline_block">
            <Select allowClear style={{ width: '180px' }} options={[{ value: 0, label: <span>未支付</span> }, { value: 1, label: <span>已支付</span> }]}></Select>
          </Form.Item>

          <Form.Item className="inline_block">
            <Button htmlType="submit" type="primary">查询</Button>
          </Form.Item>

          <Form.Item className="inline_block">
            <Button type="primary" onClick={exportExcels}>导出</Button>
          </Form.Item>

          <Form.Item className="inline_block">
            <Button type="primary" onClick={showUpdateStatusModal}>修改状态</Button>
          </Form.Item>

          <Form.Item className="inline_block">
            <Button type="primary" onClick={showUpdateMessageModal}>修改订单备注</Button>
          </Form.Item>

        </Form>
      </Card>

      <Card>
        <Spin spinning={loading}>
          <Table
            pagination={{ total, pageSize: page_size, current: page, onChange: (page) => updatePage(page) }}
            dataSource={list}
            columns={columns}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            scroll={{ x: 'max-content', y: 55 * 10 }}
          />
        </Spin>
      </Card>
    </Container>
  )
}