import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    padding: 10px 0;
    height: 120px;
    position: sticky;
    top: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    z-index: 999;

    .phone_menu {
        position: fixed;
        left: -300px;
    }

    &.fixed {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .context {
            box-sizing: border-box;
            flex-direction: row;
            padding-right: 30px;
            gap: 30px;

            .menu {
                justify-content: flex-start;
            }
        }
    }


    .context {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search {
        position: absolute;
        max-width: 1200px;
        width: 100%;
        height: 30px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9999;
        margin: 0 auto;

        svg {
            position: absolute;
            right: 0;
            top: 10px;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }

    .img_box {
        width: 80px;
        height: 80px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .menu {
        width: 700px;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 5px 30px;
        padding: 10px 0;

        .item {
            cursor: pointer;
            transition: all .3s;

            span {
                color: #000;

                &.active {
                    color: red;
                }

                &:hover {
                    color: red;
                }
            }
        }

    }

    @media screen and (min-width: 1000px) {
        .search {
            .menu_btn {
                display: none;
            }
        }

    }

    @media screen and (max-width: 1000px) {
        height: 80px;
        padding: 0 30px;
        box-sizing: border-box;

        .phone_menu {
            position: fixed;
            left: -100vw;
            top: 0;
            width: 100vw;
            height: 100vh;
            transition: all .3s;
            z-index: 10000;

            &.show {
                /* animation: showMenu .3s forwards; */
                transform: translateX(100vw);

                .mark {
                    background-color: rgba(100, 100, 111, 0.2);
                    transform: translateX(100vw);
                }
            }

            .mark {
                position: absolute;
                left: -100vw;
                top: 0;
                width: 100vw;
                height: 100vh;
                z-index: -1;
                transition: all .3s .3s;
            }

            .content {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9000;
                width: 300px;
                height: 100vh;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 15px;
                box-sizing: border-box;

                .item {
                    cursor: pointer;
                    transition: all .3s;

                    span {
                        color: #000;

                        &.active {
                            color: red;
                        }

                        &:hover {
                            color: red;
                        }
                    }
                }
            }
        }

        .context {
            align-items: flex-start;
            position: rellative;
            z-index:10000;
            width:fit-content;
            margin:0 0;

            .menu {
                display: none;
            }
        }

        .search {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 30px;
            padding: 0 30px;
            box-sizing: border-box;

            svg {
                width: 25px;
                height: 25px;
                position: static;
            }
        }

    }

    @keyframes showMenu {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(35vw);
        }
    }

`

