const currencyList = [
  { value: 'CNY', label: '人民币 (CNY)', icon: '¥' },
  { value: 'THB', label: '泰铢 (THB)', icon: '฿' },
  { value: 'SGD', label: '新加坡币 (SGD)', icon: 'S$' },
  { value: 'HKD', label: '港币 (HKD)', icon: 'HK$' },
  { value: 'TWD', label: '台湾货币 - 新台币 (TWD)', icon: 'NT$' },
  { value: 'MYR', label: '马来西亚货币 - 马来西亚林吉特 (MYR)', icon: 'RM' },
  { value: 'USD', label: '美元 (USD)', icon: '$' },
  { value: 'VND', label: '越南币 (VND)', icon: '₫' },
  { value: 'PHP', label: '菲律宾币 (PHP)', icon: '₱' },
  { value: 'IDR', label: '印尼币 (IDR)', icon: 'Rp' },
  { value: 'AED', label: '阿联酋迪拉姆 (AED)', icon: 'د.إ' },
  { value: 'EUR', label: '欧元 (EUR)', icon: '€' },
  { value: 'GBP', label: '英镑 (GBP)', icon: '£' },
  { value: 'JPY', label: '日元 (JPY)', icon: '¥' }
];

export default currencyList;