import React, { Component } from "react";
import { Layout, Menu, Button } from "antd";
import "./menu.css";
import routers from "../../router";
import { Route, Link } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";

const { Header, Sider, Content } = Layout;

class MyMneu extends Component {
  constructor(props) {
    super(props);
    this.pathname = props.location.pathname.indexOf("/article/add") !== -1 ? "/article" : props.location.pathname;
    this.state = {
      collapsed: false,
      userinfo: JSON.parse(window.localStorage.getItem("userinfo")) || {},
      selectedKey: this.pathname === "/" ? "/home" : this.pathname
    };
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const routes = routers.filter(item => {
      if (item?.meta?.auth) return this.state.userinfo.type === 1
      return true
    })
    
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <div className="logo">
            <span>在线商店</span>
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={this.state.selectedKey}>
            {routes.map(item => {
              return (
                <Menu.Item key={`/home${item.path}`} icon={item.icon}>
                  <Link to={`/home${item.path}`}>{item.name}</Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: "0" }}>
            <div className="logout">
              <Button type="primary" onClick={this.handleLogout} icon={<LogoutOutlined />}>
                退出登录
              </Button>
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              flexShrink: 0,
            }}
          >
            {routes.map(item => (
              <Route key={item.path} path={`/home${item.path}`} component={item.component}></Route>
            ))}
          </Content>
        </Layout>
      </Layout>
    );
  }

  // 退出登录
  handleLogout = () => {
    window.localStorage.removeItem("token");
    this.props.history.replace("/login");
  };
}

export default MyMneu;
