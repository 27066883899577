import styled from "styled-components";

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`

export const Banner = styled.div`
  width: 100%;
  --height: 500px;
  height: var(--height);

  .img_box{
    width: 100%;
    height: var(--height);
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 999px) {
    --height: 250px;
  }
`