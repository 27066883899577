import React, {useState, useEffect} from 'react';

import {Form, Tag, Button, Checkbox, Modal, Input, message, Table, Select} from 'antd';
import UploadList from "../../component/upload"


import {putConfigOne, getConfig} from "../../api/config"
import {getCountryList} from "../../api/location"

const defaultConfig = [
  { name: 'logo', values: '', key: 0 },
  { name: 'banners', values: '', key: 1 },
  { name: 'send_list', value: '', key: 3 },
  { name: 'affix_menus', value: '', key: 4 },
  { name: 'language', value: '', key: 6 },
  { name: 'country', value: '', key: 7 },
  { name: 'host', value: '', key: 8 },
  { name: 'adv_code_fackbook', value: '', key: 9 },
  { name: 'adv_code_google', value: '', key: 10 },
  { name: 'adv_code_tiktok', value: '', key: 11 }
]

export default () => {

  const [send_list, updateSendList] = useState([])
  const [addSend, updateOpenAddSend] = useState(false)

  const [showAffixModel, updateShowAffixModel] = useState(false)
  const [affixMenus, updateAffixMenus] = useState([])
  const [loading, updateLoading] = useState(true)
  const [configs, initConfig] = useState([])
  const [countryList, updateCountryList] = useState([])
  const [selectCountry, updateSelectCountry] = useState([])


  useEffect(() => {
    getConfig().then(res => {

      const data = []
      for (let i = 0; i < defaultConfig.length; i++) {
        const item = res.data.find(item => item.name === defaultConfig[i].name)
        if (item) {
          data.push({ ...item, key: item.id })
        } else {
          data.push(defaultConfig[i])
        }
      }

      initConfig(data)

      updateLoading(false)
    })
    getCountryList().then(res => {
      updateCountryList(res.data.map(item => {
        item.key = item.id
        item.value = item.id
        item.label = item.name
        return item
      }))
    })
  }, []);

  useEffect(() => {
    if (!configs.length) return
    const sendConfig = configs.filter(item => item.name === 'send_list')
    updateSendList(sendConfig[0]?.value?.split(',')?.filter(Boolean))

    const affixMenus = configs.filter(item => item.name === 'affix_menus')[0]?.value
    affixMenus && updateAffixMenus(JSON.parse(affixMenus))

    const country = configs.filter(item => item.name === 'country')[0]?.value
    if (!country) return
    updateSelectCountry(country.split(',').map(item => +item))
  }, [configs])

  const handleFinishForm = (values) => {
    updateOpenAddSend(false)
    updateConfig('send_list', [...send_list, values.name].join(','));
  }

  const handleFinishFormByMenus = (values) => {
    updateShowAffixModel(false)
    updateConfig('affix_menus', JSON.stringify([...affixMenus, values]));
  }

  const updateConfig = async (name, value) => {
    updateLoading(true)
    await putConfigOne(name, value)
    getConfig().then(res => {
      const data = []
      for (let i = 0; i < defaultConfig.length; i++) {
        const item = res.data.find(item => item.name === defaultConfig[i].name)
        if (item) {
          data.push({ ...item, key: item.id })
        } else {
          data.push(defaultConfig[i])
        }
      }
      initConfig(data)
      message.success("修改成功")
      updateLoading(false)
    })
  }

  const configDict = {
    logo: 'logo',
    send_list: '配送方式',
    banners: '轮播图',
    affix_menus: '侧边按钮',
    language: '语言',
    country: '销售地区',
    host: "网站域名",
    adv_code_fackbook: "facebook广告代码",
    adv_code_google: "google广告代码",
    adv_code_tiktok: "tiktok广告代码"
  }

  const configRender = {
    logo:(config) => <UploadList value={config.value} onChange={(data) => updateConfig(config.name, data)} />,
    banners: (config) => <UploadList value={config.value?.split(',')} list onChange={(data) => updateConfig(config.name, data.join(','))} />,
    send_list: (config) => {
      return (
        <>
          {send_list.map(item =>
            <Tag
              key={item}
              closeIcon
              onClose={() => updateConfig('send_list', send_list.filter(li => li !== item).join(','))}
            >
              {item}
            </Tag>
          )}
          <Button size='small' type="primary" onClick={() => updateOpenAddSend(true)}>添加</Button>
        </>
      )
    },
    affix_menus: (config) => {
      return (
        <>
          {affixMenus.map(item =>
            <Tag
              key={item.logo}
              closeIcon
              onClose={() => updateConfig('affix_menus', JSON.stringify(affixMenus.filter(li => li.logo !== item.logo)))}
            >
              {item.link || item.dialog}
            </Tag>
          )}
          <Button type="primary" onClick={() => updateShowAffixModel(true)} size='small'>添加</Button>
        </>
      )
    },
    language: (config) => {
      return (
        <>
          <Select value={config.value} onChange={(value) => updateConfig('language', value)} style={{ width: '120px' }}>
            <Select.Option value="zh-CN">中文</Select.Option>
            <Select.Option value="en-US">英文</Select.Option>
          </Select>
        </>
      )
    },
    country: (config) => <Select
      showSearch
      options={countryList}
      mode="multiple"
      value={selectCountry}
      style={{ minWidth: '200px' }}
      filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
      onChange={(value) => updateSelectCountry(value)}
      onBlur={() => updateConfig('country', selectCountry.join(','))}
      onDeselect={(value) => updateConfig('country', selectCountry.filter(item => item !== value).join(','))}
    />,
    host: (config) => <Input defaultValue={config.value} onBlur={(e) => updateConfig('host', e.target.value)} />,
    adv_code_fackbook: (config) => <Input defaultValue={config.value} onBlur={(e) => updateConfig('adv_code_fackbook', e.target.value)} />,
    adv_code_google: (config) => <Input defaultValue={config.value} onBlur={(e) => updateConfig('adv_code_google', e.target.value)} />,
    adv_code_tiktok: (config) => <Input defaultValue={config.value} onBlur={(e) => updateConfig('adv_code_tiktok', e.target.value)} />,
  }

  const columns = [
    {
      title: '配置项',
      dataIndex: 'name',
      key: 'name',
      render: (text) => configDict[text] || text
    },
    {
      title: '内容',
      key: 'value',
      render: (config) => (configRender[config.name] || ((config) => config.value))(config)
    },
  ]


  return (
    <>
      <Table dataSource={configs} columns={columns} loading={loading} pagination={false} />
      <Modal open={showAffixModel} title="添加侧边按钮" footer={null} destroyOnClose onCancel={() => updateShowAffixModel(false)}>
        <Form name="formData" labelCol={{ span: 6 }} labelAlign="right" wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }} onFinish={handleFinishFormByMenus}>

          <Form.Item label="图标" name="logo" rules={[{ required: true, message: '图标不能为空' }]}>
            <UploadList />
          </Form.Item>

          <Form.Item label="链接" name="link">
            <Input />
          </Form.Item>

          <Form.Item label="弹窗文字" name="dialog">
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button type="primary" htmlType="submit">提交</Button>
          </Form.Item>

        </Form>
      </Modal>
      <Modal open={addSend} title="添加物流方式" footer={null} destroyOnClose onCancel={() => updateOpenAddSend(false)}>
        <Form name="formData" labelCol={{ span: 6 }} labelAlign="right" wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }} onFinish={handleFinishForm}>

          <Form.Item label="物流方式" name="name" rules={[{ required: true, message: '物流方式不能为空' }]}>
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button type="primary" htmlType="submit">提交</Button>
          </Form.Item>

        </Form>
      </Modal>
    </>
  )
}