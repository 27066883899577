import request from "../tool/request";

const baseUrl = '/api/article'

export const getArticleList = (params) => request({ method: 'GET', url: `${baseUrl}/`, params })

export const getArticleDetail = (id) => request.get(`${baseUrl}/${id}`)

export const addArticle = (data) => request.post(`${baseUrl}/`, data)

export const updateArticle = (id, data) => request({ method: 'put', url: `${baseUrl}/${id}`, data })

export const delArticle = (id) => request.delete(`${baseUrl}/${id}`)