import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Spin, message } from 'antd'

import Upload from "../upload"
import { SpecificationsBox } from "./style"
import { PlusOutlined } from "@ant-design/icons";

import { addSpecifications, deleteSpecifications, getSpecificationsByGoodsId } from "../../api/specifications"

export default (props) => {

  const [showAdd, updateShow] = useState(false)
  const [logo, handleChangeLogo] = useState('')
  const [loading, updateLoading] = useState(true)
  const [list, updateList] = useState([])

  const handleSubmit = (values) => {
    updateLoading(true)
    if (props.goodsId !== "0") values.goods = props.goodsId
    addSpecifications(values).then(res => {
      updateShow(false)
      const newList = [...list, res.data]
      updateList(newList)
      message.success("添加成功，已保存规格数据")
    }).finally(updateLoading(false))
  }

  const deleteSpec = (id) => {
    Modal.confirm({
      title: '确定要删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        updateLoading(true)
        deleteSpecifications(id).then(res => {
          const newList = list.filter(item => item !== id)
          updateList(newList)
        }).finally(updateLoading(false))
      }
    })
  }

  useEffect(() => {
    const goodsId = props.goodsId
    if (goodsId != 0) {
      getSpecificationsByGoodsId(goodsId).then(res => {
        updateList(res.data)
      }).finally(updateLoading(false))
    } else {
      updateLoading(false)
    }
  }, [props.goodsId])

  return (
    <Spin spinning={loading}>
      <SpecificationsBox>
        {
          list.map(item => (
            <div className="spec_box" onClick={() => deleteSpec(item.id)}>
              {
                item.logo && (<div className="img_box">
                  <img src={item.logo} alt="" />
                </div>)
              }

              <span>{item.name}</span>
            </div>
          ))
        }
        <div className="add_btn" onClick={() => updateShow(true)}>
          <PlusOutlined />
          <div>添加规格</div>
        </div>

        <Modal title="添加商品规格" open={showAdd} footer={null} onCancel={() => updateShow(false)} keyboard={false} maskClosable={false}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit}>
            <Form.Item name="name" label="规格名称" rules={[{ required: true, message: "请输入规格名称" }]}>
              <Input placeholder="请输入规格名称"></Input>
            </Form.Item>
            <Form.Item name="logo" label="规格图片" rules={[{ required: false, message: "请输入规格图片" }]}>
              <Upload onChange={handleChangeLogo} value={logo} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>
        </Modal>

      </SpecificationsBox>
    </Spin>
  )
}