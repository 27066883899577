import Axios from "axios";
import { message } from "antd"

const request = Axios.create();

request.interceptors.request.use(
  (config) => {
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log('请求出错',error);
    
    const { status, data } = error.response
    if (status >= 500) {
      message.error("请求服务失败，请检查网络是否正常或稍后再试")
    } else {
      message.error(data.first_error)
    }
    return Promise.reject(error);
  }
);


export default request;