/**
 * 传统的富文本编辑器模式添加文章
 * 富文本开发文档地址：https://www.yuque.com/braft-editor/be/lzwpnr
 * https://braft.margox.cn/demos/basic
 * https://www.jianshu.com/p/acb0fc96b259
 */
import React, {Component} from "react";
import {Button, Input, Form, message, Spin, Select, InputNumber} from "antd";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import {Main} from "./style";

import UploadFile from "../../component/upload"

import {createGoods, getGoodsDetailAdmin, updateGoods} from "../../api/goods"
import {getCategorizeList} from "../../api/categorize"
import {uploadImage} from "../../api/image"
import {getCountryList} from "../../api/location"
import {getTemplateList} from "../../api/template"
import Specifications from "../../component/specifications";
import currencyList from "../goods/currency"

class DefaultCreate extends Component {
  constructor (props) {
    super(props);
    this.state = {
      editorState: "",
      formData: {
        logo: "",
        name: "",
        context: "",
        describe: "",
        price: 0,
        categorize: "",
        banners: [],
        specifications: '',
        currency: "CNY",
        country: '',
        total: 1,
        old_price: ''
      },
      logoLoading: false,
      loading: false,
      columnList: [],
      countryList: [],
      templateList: []
    };
    this.id = this.props.match.params.id;
    this.formRef = React.createRef();
  }

  selectCurrency = (val) => {
    this.setState({
      formData: {
        ...this.state.formData,
        currency: val
      }
    })
  }

  render () {
    const layout = { labelCol: { span: 2 }, wrapperCol: { span: 22 }, labelAlign: "right" };
    const tailLayout = { wrapperCol: { offset: 2, span: 2 } };
    const { formData, columnList, editorState, templateList } = this.state
    const selectAfter = <Select defaultValue={formData.currency} value={formData.currency} onChange={this.selectCurrency} options={currencyList}></Select>;

    return (
      <Main>
        <Spin spinning={this.state.loading}>
          <Form {...layout} ref={this.formRef} initialValues={{ banners: [] }} name="formData" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
            <Form.Item label="商品名称" name="name" rules={[{ required: true, message: "请输入商品名称" }]}>
              <Input defaultValue={formData.name} />
            </Form.Item>

            <Form.Item label="商品描述" name="describe" rules={[{ required: true, message: "请输入商品描述" }]}>
              <Input value={formData.describe} />
            </Form.Item>

            <Form.Item label="商品封面" name="logo">
              <UploadFile list={false} onChange={this.handleUploadChange} />
            </Form.Item>

            <Form.Item label="商品轮播图" name="banners">
              <UploadFile list={true} onChange={this.handleUploadChangeBanner} />
            </Form.Item>

            <Form.Item label="商品模板" name="template">
              <Select options={templateList}></Select>
            </Form.Item>

            <Form.Item label="商品分类" name="categorize" rules={[{ required: false, message: "请选择商品分类" }]}>
              <Select options={columnList}></Select>
            </Form.Item>

            <Form.Item label="商品规格" name="specifications" rules={[{ required: false, message: "请添加商品规格" }]}>
              <Specifications goodsId={this.id} />
            </Form.Item>

            <Form.Item label="商品价格" name="price" rules={[{ required: true, message: "请输入商品价格" }]}>
              <InputNumber min={0.01} addonAfter={selectAfter} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="商品原价" name="old_price">
              <InputNumber min={0.01} addonAfter={selectAfter} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="商品库存" name="total" rules={[{ required: false, message: "请输入商品库存数" }]}>
              <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="出售地区" name="country">
              <Select
                showSearch
                options={this.state.countryList}
                mode="multiple"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item label="商品详情" name="context">
              <div className="my-component">
                <BraftEditor
                  value={editorState}
                  onChange={this.handleEditorChange}
                  onSave={this.submitContent}
                  media={{ uploadFn: this.handleEditorUploadMedia }}
                  placeholder="请输入商品详情"
                />
              </div>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">保存</Button>
            </Form.Item>
          </Form>
        </Spin>

      </Main>
    );
  }

  // 获取栏目信息
  handleGetColumnList () {
    this.setState({ loading: true })

    const params = { page: 1, page_size: 99999 }
    getCategorizeList(params).then(res => {
      const { list } = res.data
      this.setState({
        columnList: list.map(item => {
          item.key = item.id
          item.value = item.id
          item.label = item.name
          return item
        })
      }, () => {
        this.id !== "0" && this.handleGetGoodDetail(this.id)
      })
    }).finally(() => {
      this.id === "0" && this.setState({ loading: false })
    })
  }

  handleGetGoodDetail = async (id) => {
    const { columnList } = this.state
    getGoodsDetailAdmin(id).then(res => {
      const { formData } = this.state;
      const { logo, name, describe, price, categorize, context, cate, banners, currency, total, country, old_price, template } = res.data
      const initBanner = new Set(banners?.split(',') || [])
      formData.logo = logo;
      formData.id = id;
      formData.name = name
      formData.describe = describe
      formData.price = price
      formData.categorize = categorize
      formData.banners = [...initBanner]
      formData.currency = currency || "CNY"
      formData.old_price = old_price
      formData.template = template
      if (country) {
        formData.country = country.split(',').filter(Boolean).map(item => +item)
      } else {
        formData.country = []
      }

      formData.total = total
      this.setState({
        formData,
        editorState: BraftEditor.createEditorState(context),
        loading: false,
      });
      this.formRef.current.setFieldsValue({
        ...formData,
        categorize: columnList.find(item => item.name === cate?.name)?.id
      });
    }).finally(() => {
      this.setState({ loading: false })
    }).catch(() => {
      this.props.history.push({ pathname: '/home/goods' })
    })
  }

  // 富文本上传媒体文件
  handleEditorUploadMedia = param => {
    console.log(param);
    const fd = new FormData();
    fd.append("file", param.file);
    uploadImage(fd).then(res => {
      const { url } = res.data
      param.success({
        url: url,
        meta: {
          loop: false, // 指定音视频是否循环播放
          autoPlay: false, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
          playsinline: true
        },
      });
    })
  };


  // 上传封面
  handleUploadChange = (file) => {
    const { formData } = this.state
    formData.logo = file
    this.setState({ formData })
  }

  handleUploadChangeBanner = (files) => {
    const { formData } = this.state
    formData.banners = files
    this.setState({ formData })
  }

  componentDidMount () {
    this.handleGetColumnList()
    getCountryList().then(res => {
      this.setState({
        countryList: res.data.map(item => {
          item.key = item.id
          item.value = item.id
          item.label = item.name
          return item
        })
      })
    })
    getTemplateList().then(res => {
      this.setState({
        templateList: res.data.map(item => {
          return {
            label: item.name,
            value: item.key
          }
        })
      })
    })
  }

  onFinish = values => {
    const { editorState, formData } = this.state;

    if (editorState.toHTML() === "") {
      message.error("请输入文章详情");
      return;
    }

    values.context = editorState.toHTML();
    values.logo = formData.logo;

    values.banners = formData.banners.join(',')
    values.ctype = 0;
    values.currency = formData.currency
    values.country = values.country?.join(',')
    if (formData.id) {
      updateGoods(formData.id, values).then(() => {
        message.success("修改成功");
        this.props.history.push("/home/goods");
      })
    } else {
      createGoods(values).then(() => {
        message.success("发布成功");
        this.props.history.push("/home/goods");
      })
    }
  };

  onFinishFailed = errorInfo => {
    message.error("请完善表单内容");
  };

  handleEditorChange = editorState => {
    const { formData } = this.state;
    formData.context = editorState.toHTML();
    this.setState({ editorState, formData });
  };

  submitContent = () => {
    console.log(this.state.editorState.toHTML());
  };
}

export default DefaultCreate;
