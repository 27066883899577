import React, { useEffect, useState } from "react";
import { Carousel } from "antd"

import Header from "../component/header/"
import Cloumn from "../component/cloumn/"
import Footer from "../component/footer/"
import { Content, Banner } from "./style"

import { getCategorizeList } from "../../../api/categorize"
import {getConfigClient} from "../../../api/config"

export default () => {

  const [banner, updateBanner] = useState([])

  const [cloumnData, initCloumn] = useState([])

  useEffect(() => {
    getCategorizeList().then(res => {
      initCloumn(res.data.list)
    })
    getConfigClient("banners").then(res => {
      const banners = res.data[0]?.value
      if(banners){
        updateBanner(banners.split(","))
      }
    })
  }, [])

  return (
    <>
      <Header cloumnData={cloumnData} />

      <Content>
        <Banner>
          <Carousel autoplay>
            {
              banner.map((item, index) => {
                return (
                  <div key={index} className="img_box">
                    <img src={item} alt="" />
                  </div>
                )
              })
            }
          </Carousel>
        </Banner>
        {
          cloumnData.map((item, index) => <Cloumn key={index} column={item} />)
        }
      </Content>

      <Footer />


    </>

  );
}