import request from "../tool/request";

const baseUrl = '/api/location'

export const getCountryList = (params) => {
  return request({
    method: 'get',
    url: `${baseUrl}/country`,
    params
  })
}

export const getCityListByGoods = (goods) => {
  return request({
    method: 'get',
    url: `${baseUrl}/country/${goods}`,
  })
}

export const getStateList = (country_id) => request.get(`${baseUrl}/state/${country_id}`)

export const getCityList = (state_id) => request.get(`${baseUrl}/city/${state_id}`)

export const getRegionList = (city_id) => request.get(`${baseUrl}/region/${city_id}`)